module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/mainpage' && templateVars.config ?
				templateVars.config :
				{
	"name": "mainpage",
	"containerMinHeight": "auto",
	"simulatedPagination": {
		"render": true,
		"itemsCount": 8
	},
	"options": {
		"direction": "vertical",
		"speed": 1000,
		"loop": false,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": true
		},
		"pagination": false,
		"navigation": {
			"prevEl": ".js-slider-button--prev",
			"nextEl": ".js-slider-button--next"
		},
		"autoplay": false,
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": true
		},
		"mousewheel": true,
		"nested": false,
		"runCallbacksOnInit": false,
		"hashNavigation": false,
		"preventInteractionOnTransition": true,
		"parallax": false,
		"init": false
	},
	"subSliders": [
		{
			"parentSlideID": 1,
			"sliderWrapperClass": ".js-sub-slider-wrapper",
			"options": {
				"direction": "vertical",
				"speed": 1200,
				"loop": false,
				"effect": "slide",
				"fadeEffect": {
					"crossFade": true
				},
				"pagination": false,
				"navigation": {
					"prevEl": ".js-sub-slider-button--prev",
					"nextEl": ".js-sub-slider-button--next"
				},
				"autoplay": false,
				"initialSlide": 0,
				"simulateTouch": false,
				"keyboard": {
					"enabled": true,
					"onlyInViewport": false
				},
				"mousewheel": true,
				"nested": true,
				"runCallbacksOnInit": false,
				"hashNavigation": false,
				"preventInteractionOnTransition": true,
				"parallax": false,
				"followFinger": false,
				"allowSlideNext": false,
				"allowSlidePrev": false
			}
		}
	]
};