const config = require('./config.yaml');
const CSlider = require('../../index.js');
// const Tools = require('../../Tools.js');
const bodyScrollLock = require('body-scroll-lock/lib/bodyScrollLock.js');

class CSlider_mainpage extends CSlider {
	constructor() {
		super();
		this.config = config;
		// this.touch = false;
	}

	async init() {
		this.$sliderWrapper = $(`.js-slider-wrapper--${config.name}`);

		if (this.$sliderWrapper.length) {
			// this.$bgMain = this.$sliderWrapper.find('.js-bg-main');
			this.$bgFlat = this.$sliderWrapper.find('.js-bg-flat');
			this.$slideMainContent = this.$sliderWrapper.find('.js-slide-main');
			this.$form = this.$sliderWrapper.find('.js-form--hire-us');
			this.$popup = $('.js-popup--hire-us');
			this.$popupInner = this.$popup.find('.b-popup__inner')[0];
			this.$mouse = this.$sliderWrapper.find('.js-slide-mouse');
			this.$pagination = this.$sliderWrapper.find('.js-progress-bar-wrapper');
			this.states = {
				canSlide: true,
				slidingThrough: false,
				prevSlide: null,
				targetSlide: null,
				to: 0,
			};
			this.browsers = {
				isFirefox: $('html').hasClass('browser-firefox'),
				// isSafari: $('html').hasClass('browser-safari'),
			};
			this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
			this.enableBodyScroll = bodyScrollLock.enableBodyScroll;
			// this.$navLogo = $('.js-nav-panel-logo');
			this.excludeCustomEffect = this.browsers.isFirefox;
			// const states = this.states;

			Promise.resolve()
				.then(() => {
					return new Promise((resolve, reject) => {
						const swiperConfig = {
							watchSlidesProgress: true,
							on: {
								init: () => {
									AR.events.emit('onInit');
									this.onInit();
									resolve();
								},
								slideChangeTransitionStart: () => {
									AR.events.emit('onSlideChangeTransitionStart_general');
									AR.events.emit('onSlideChangeTransitionStart');
									this.onSlideChangeTransitionStart();

									this.states.canSlide = false;
									//переключение хеша в адресной строке
									let slideHash = this.getFullHashBySlideID(this.swiper.activeIndex);
									if (slideHash) {
										this.Tools.setPageHash(slideHash);
									}

									this.setPaginationState();
									this.checkBgVisibilityBefore();
									this.showContentBefore();
									// this.logoController();

									// if (!this.touch) {
									this.checkSwipersStateBefore();
									this.handlePopup();
									// }

									if (this.swiper.isBeginning) {
										this.$pagination.addClass('on-begin');
									} else {
										this.$pagination.removeClass('on-begin');
									}

									setTimeout(() => {
										this.setPaginationColor();
									}, this.config.options.speed / 4);

									if (this.swiper.previousIndex > 0 && this.swiper.activeIndex == 0) {
										this.fixMainSvg();
										this.onResizeFixMainSvg();
									}

									if (this.swiper.previousIndex == 0) {
										this.offResizeFixMainSvg();
									}
								},
								slideChangeTransitionEnd: () => {
									this.states.canSlide = true;
									this.setAnimatedSlideState();
									this.checkBgVisibilityAfter();
									this.hideContentAfter();
									this.fixSlideThorugh();
									this.fixSubSlider();

									// if (!this.touch) {
									this.checkSwipersStateAfter();
									// }

									this.setInnerTranslates();
								},
							}
						};

						return this.initSlider(this.$sliderWrapper, swiperConfig);
					});
				})
				.then(() => {
					return this.initSlidesLogic();
				})
				.then(() => {
					let addressHash = this.Tools.getPageHash();
					//Проверка, нужно ли переключиться к слайду по хешу страницы
					if (addressHash) {
						//разделяем хэш по разделителю
						let arHashSlideID = this.Tools.divideHashByDelimiter();
						this.goToSlideByHash(arHashSlideID);
					} else {
						//переключение хеша в адресной строке
						let slideHash = this.getFullHashBySlideID(this.swiper.activeIndex);
						if (slideHash) {
							this.Tools.setPageHash(slideHash);
						}
					}

					$('.b-slider-item__content, .b-sub-slider-item__content').css('display', 'none');

					this.showContentBefore();
					this.showContentBefore(true);
					this.checkBgVisibilityBefore();
					this.fixPopupScroll();
					this.initMouse();
					this.onResizeFixMainSvg();
					// this.onLogoClick();
				})
				.then(() => {
					$(window).on('load', () => {
						this.fixMainSvg();
						this.setAnimatedSlideState(true, true);

						const preloader = document.querySelector('.js-preloader');
						const progressState = this.initProgressBar(this.$pagination);

						if (progressState) {
							this.initPagination();
							this.setPaginationColor();
						}

						$(preloader).velocity('transition.fadeOut', {
							duration: 800,
							delay: 500,
						});

						setTimeout(() => {
							this.$oneSvg = this.$slideMainContent.find('#num-one');

							// preloader.classList.add('hide-bg');

							if (this.$oneSvg.length) {
								this.defineBgOneAnimation();

								if (this.swiper.activeIndex == 0) {
									this.bgOneAnimation();
								}
							} else {
								AR.events.on('svgRendered', $el => {
									if ($el.attr('id') == 'num-one') {
										this.$oneSvg = $el;
										this.defineBgOneAnimation();

										if (this.swiper.activeIndex == 0) {
											this.bgOneAnimation();
										}
									}
								});
							}
						}, 800);
					});
				});
		}
	}

	fixSubSlider() {
		const subSwiper = this.subSliders[1];

		subSwiper.allowSlidePrev = true;
		subSwiper.allowSlideNext = true;

		if (this.swiper.activeIndex == 0) {
			subSwiper.slideTo(0, 0);
		} else if (this.swiper.activeIndex > 1) {
			subSwiper.slideTo(subSwiper.slides.length - 1, 0);
		}
	}

	onSlideChangeTransitionStart() {
		const swiper = this.swiper;
		const prevSlide = this.states.prevSlide || swiper.slides[swiper.previousIndex];
		const targetSlide = this.states.targetSlide || swiper.slides[swiper.activeIndex];
		const slideInner = targetSlide.querySelector('.l-slider-item__inner');
		let speed = 0;
		let innerTranslate = 0;

		if (!this.excludeCustomEffect && this.states.canSlide) {
			const slideProgress = swiper.previousIndex > swiper.activeIndex ? -1 : 1;
			const innerOffset = swiper.height * 0.9;

			speed = this.config.options.speed;
			innerTranslate = slideProgress * innerOffset;
		}

		slideInner.style.transition = `${speed}ms`;
		slideInner.style.transform = 'translate3d(0, 0, 0)';

		if (prevSlide) {
			const slideInner2 = prevSlide.querySelector('.l-slider-item__inner');
			slideInner2.style.transition = `${speed}ms`;
			slideInner2.style.transform = `translate3d(0, ${innerTranslate}px, 0)`;
		}
	}

	setInnerTranslates() {
		Array.from(this.swiper.slides).forEach((item, idx) => {
			const inner = item.querySelector('.l-slider-item__inner');

			inner.style.transition = '';

			if (idx < this.swiper.activeIndex) {
				inner.style.transform = `translate3d(0, ${this.swiper.height * 0.9}px, 0)`;
			} else if (idx > this.swiper.activeIndex) {
				inner.style.transform = `translate3d(0, -${this.swiper.height * 0.9}px, 0)`;
			} else {
				inner.style.transform = 'translate3d(0, 0, 0)';
			}
		});
	}

	setPaginationColor() {
		if (this.swiper.slides[this.swiper.activeIndex].classList.contains('with-sub')) {
			this.$pagination.removeClass('_light');
		} else {
			this.$pagination.addClass('_light');
		}
	}

	initPagination() {
		const bulletsCollection = [...document.querySelectorAll('.js-progress-bar-wrapper .js-set-pagination-size')];
		const allSlides = [...document.querySelectorAll('.swiper-slide')];
		const targetSlides = allSlides.filter((item) => !item.querySelector('.js-sub-slider-wrapper'));
		const slideWithSub = allSlides.find((item) => item.querySelector('.js-sub-slider-wrapper'));
		// const slideWithSubInner = slideWithSub.querySelector('.l-slider-item__inner');

		slideWithSub.classList.add('with-sub');

		const swiper = this.swiper;
		const subSwiper = this.subSliders[1];
		const withSubIdx = Array.from(swiper.slides).findIndex((item) => item == slideWithSub);

		targetSlides.forEach((item, idx) => {
			const bullet = bulletsCollection[idx];
			const id = bullet.dataset.bulletId;

			item.dataset.bulletId = id;

			if (item.classList.contains('js-sub-slider-item')) {
				bullet.classList.add('is-sub');
			} else {
				bullet.classList.add('is-main');
			}
		});

		bulletsCollection.forEach((item, idx) => {
			const isSub = item.classList.contains('is-sub');
			const bulletId = item.dataset.bulletId;

			item.addEventListener('click', () => {
				const activeSlide = swiper.slides[swiper.activeIndex];
				// const activeSubSlide = subSwiper.slides[subSwiper.activeIndex];
				const onSub = activeSlide.classList.contains('with-sub');
				const subSlideToIdx = Array.from(subSwiper.slides).findIndex((item) => item.dataset.bulletId == bulletId);
				let slideToIdx = Array.from(swiper.slides).findIndex((item) => item.dataset.bulletId == bulletId);

				if (slideToIdx == -1) {
					slideToIdx = withSubIdx;
				}

				if (onSub && isSub) {
					subSwiper.slideTo(subSlideToIdx);
				} else {
					if (isSub) {
						subSwiper.allowSlidePrev = true;
						subSwiper.allowSlideNext = true;
						subSwiper.slideTo(subSlideToIdx, 0);
					}

					if (swiper.activeIndex + 1 == slideToIdx) {
						this.swiper.allowSlidePrev = true;
						this.swiper.allowSlideNext = true;
						this.swiper.slideNext();
					} else if (swiper.activeIndex - 1 == slideToIdx) {
						this.swiper.allowSlidePrev = true;
						this.swiper.allowSlideNext = true;
						this.swiper.slidePrev();
					} else {
						this.slideThrough(slideToIdx, onSub);
					}
				}
			});
		});
	}

	// logoController() {
	// 	if (this.swiper.activeIndex == 0) {
	// 		this.$navLogo.addClass('is-static');
	// 	} else if (this.swiper.previousIndex == 0) {
	// 		this.$navLogo.removeClass('is-static');
	// 	}
	// }

	// onLogoClick() {
	// 	this.$navLogo.on('click', e => {
	// 		if (this.swiper.activeIndex !== 0) {
	// 			this.slideThrough();
	// 		}
	// 	});
	// }

	slideThrough(idx, onSub) {
		const activeIndex = this.swiper.activeIndex;
		const subSlider = this.subSliders[1];
		const activeSlide = this.swiper.slides[activeIndex];
		this.states.slidingThrough = true;

		let subToIdx = null;
		let toId = null;
		const targetSlide = this.swiper.slides[idx];

		this.states.prevSlide = activeSlide;
		this.states.targetSlide = targetSlide;

		if (idx < activeIndex) {
			subToIdx = 0;
			toId = idx + 1;

			this.states.to = -1;
		} else {
			subToIdx = subSlider.slides.length - 1;
			toId = idx - 1;

			this.states.to = 1;
		}

		const slides = Array.from(this.swiper.slides);
		const slidesForHide = slides.filter((elem, i) => {
			if (idx < activeIndex) {
				return i > idx && i < activeIndex;
			} else {
				return i < idx && i > activeIndex;
			}
		});


		this.swiper.allowSlidePrev = true;
		this.swiper.allowSlideNext = true;
		this.swiper.slideTo(toId, 0, false);

		$(this.swiper.slides[activeIndex])
			.children('.l-slider-item__inner')
			.css('transform', 'translate3d(0, 0, 0)');
		$(slidesForHide)
			.addClass('hidden-slide')
			.css('display', 'none');

		if (idx < activeIndex) {
			this.swiper.slideTo(toId, 0, false);
		} else {
			slides.forEach((item, i) => {
				if (i >= activeIndex) {
					item.style.transform = `translate3d(0, ${this.swiper.height * slidesForHide.length}px, 0)`;
				}
			});
		}

		setTimeout(() => {
			if (idx < activeIndex) {
				this.swiper.slidePrev(this.config.options.speed);
			} else {
				this.swiper.slideNext(this.config.options.speed);
			}
		}, 20);

		setTimeout(() => {
			activeSlide.classList.remove('is-animated');
			this.hideContentAfter(false, activeIndex);
			this.checkBgVisibilityAfter(idx, activeIndex);
		}, this.config.options.speed);
	}

	fixSlideThorugh() {
		const $hiddenSlides = this.$slider.find('.hidden-slide');

		if (this.states.slidingThrough || $hiddenSlides.length) {
			$hiddenSlides
				.removeClass('hidden-slide')
				.css('display', '');

			Array.from(this.swiper.slides).forEach((item) => item.style.transform = '');

			this.states.slidingThrough = false;
			this.states.to = 0;
			this.states.targetSlide = null;
			this.states.prevSlide = null;
		}
	}

	onInit() {
		if ($('html').hasClass('feature-no-flexbox') || $('html').hasClass('browser-safari-10') || $('html').hasClass('browser-safari-9')) {
			this.fixContainerHeight();
		}
	}

	onResizeFixMainSvg() {
		window.addEventListener('resize', $.proxy(this, 'fixMainSvg'));
	}

	offResizeFixMainSvg() {
		window.removeEventListener('resize', $.proxy(this, 'fixMainSvg'));
	}

	fixMainSvg() {
		this.$slideMainContent.css('max-width', '');

		const maxHeight = (window.innerHeight / 100) * 90;
		const svgWidth = this.$slideMainContent.innerWidth();
		const svgHeight = this.$slideMainContent.innerHeight();

		if (maxHeight < svgHeight) {
			const ratio = svgWidth / svgHeight;

			this.$slideMainContent.css('max-width', maxHeight * ratio);
		}
	}

	fixPopupScroll() {
		AR.events.on('onPopupStartOpenAnimation', () => {
			this.disableBodyScroll(this.$popupInner);
		});

		AR.events.on('onPopupStartCloseAnimation', () => {
			this.enableBodyScroll(this.$popupInner);
		});
	}

	initMouse() {
		this.$mouse.on('click', () => {
			this.swiper.slideNext();
		});
	}

	handlePopup() {
		if (this.swiper.isEnd) {
			$(window).on('resize', $.proxy(this, 'onResizePopup'));
		} else if (this.swiper.previousIndex == this.swiper.slides.length - 2) {
			$(window).off('resize', $.proxy(this, 'onResizePopup'));
		}
	}

	onResizePopup() {
		if (this.$popup.hasClass('is-active') && this.$form.is(':visible')) {
			AR.components.cPopup_base.closePopup(this.$popup.data('popupId'));
		}
	}

	showContentBefore(isSub) {
		const swiper = isSub ? this.subSliders[1] : this.swiper;
		const contentClass = isSub ? '.b-sub-slider-item__content' : '.b-slider-item__content' ;
		const activeSlide = swiper.slides[swiper.activeIndex];
		const innerContent = activeSlide.querySelector(contentClass);

		if (isSub) {
			activeSlide.style.visibility = 'visible';
		}

		if (innerContent) {
			innerContent.style.display = 'block';
		}
	}

	hideContentAfter(isSub, pIndex = null) {
		const swiper = isSub ? this.subSliders[1] : this.swiper;
		const contentClass = isSub ? '.b-sub-slider-item__content' : '.b-slider-item__content' ;
		const prevIndex = typeof pIndex == 'number' ? pIndex : swiper.previousIndex;
		const prevSlide = swiper.slides[prevIndex];
		const innerContent = prevSlide.querySelector(contentClass);
		const innerAnimated = prevSlide.querySelector('.is-animated');

		if (isSub) {
			prevSlide.style.visibility = 'hidden';
		}

		if (innerContent) {
			innerContent.style.display = 'none';
		}

		if (prevSlide.classList.contains('is-animated')) {
			prevSlide.classList.remove('is-animated');
		} else if (innerAnimated) {
			innerAnimated.classList.remove('is-animated');
		}

		if (prevIndex == 0 && !isSub) {
			this.stopBgOneAnimation();
		}
	}

	checkBgVisibilityBefore() {
		const activeIndex = this.swiper.activeIndex;

		if (activeIndex == 0) {
			this.$bgFlat.css('display', 'block');
			this.$slideMainContent.css('display', 'block');
		} else if (activeIndex >= 2 && this.$bgFlat.is(':hidden')) {
			this.$bgFlat.css('display', 'block');
		}
	}

	checkBgVisibilityAfter(activeIndex = this.swiper.activeIndex, prevIndex = this.swiper.previousIndex) {
		if (prevIndex == 0) {
			// this.$bgFlat.css('display', 'none');
			this.$slideMainContent.css('display', 'none');
		} else if (activeIndex == 1) {
			this.$bgFlat.css('display', 'none');
		}
	}

	setAnimatedSlideState(isMain = true, isInit = false) {
		const activeSlide = isMain ? this.swiper.slides[this.swiper.activeIndex] : this.subSliders[1].slides[this.subSliders[1].activeIndex];

		if (isMain && activeSlide.querySelector('.js-sub-slider-wrapper')) {
			const currentSubSlide = this.subSliders[1].slides[this.subSliders[1].activeIndex];

			if (!currentSubSlide.classList.contains('is-animated')) {
				currentSubSlide.classList.add('is-animated');
			}
		} else if (!activeSlide.classList.contains('is-animated')) {
			activeSlide.classList.add('is-animated');
		}

		if (!isInit && isMain && this.swiper.activeIndex == 0) {
			this.bgOneAnimation();
		}
	}

	defineBgOneAnimation() {
		this.$texts = this.$oneSvg.find('.mp-text');
		this.$one = this.$oneSvg.find('.mp-one');
		this.$logo = this.$oneSvg.find('.mp-logo');
		this.$addText = this.$oneSvg.find('.mp-num');
		this.$sortedTexts = this.$texts.sort((a, b) => $(a).data('id') - $(b).data('id'));
		this.$initTexts = this.$texts.filter((i, el) => $(el).data('id') == 1);
		this.$otherTexts = this.$texts.filter((i, el) => $(el).data('id') != 1);
		this.$topTexts = this.$sortedTexts.filter((i, el) => $(el).data('side') == 'top');
		this.$botTexts = this.$sortedTexts.filter((i, el) => $(el).data('side') == 'bot');
	}

	stopBgOneAnimation() {
		if (this.$texts) {
			this.$one.velocity('stop', true).velocity('fadeOut', 0);
			this.$logo.velocity('stop', true).velocity('fadeOut', 0);
			this.$addText.velocity('stop', true).css('opacity', 0);
			this.$texts.velocity('stop', true).css('opacity', 0);
		}
	}

	bgOneAnimation() {
		this.$addText.velocity({
			translateX: '-30px',
		}, 0);
		this.$otherTexts.each((i, el) => {
			const $el = $(el);
			const isTop = $el.data('side') == 'top';
			const translateValue = isTop ? '140px' : '-170px';

			$el.velocity({
				translateY: translateValue
			}, 0);
		});
		this.$initTexts.velocity({
			translateX: '30px',
			translateY: '0px'
		}, 0);

		setTimeout(() => {
			this.$one.velocity('fadeIn', {
				duration: 800,
				easing: 'linear',
				delay: 300
			});
			this.$logo.velocity('fadeIn', {
				duration: 800,
				easing: 'linear',
				delay: 300
			});
			this.$addText.velocity({
				translateX: '0px',
				opacity: 1
			}, {
				duration: 1100,
				easing: 'ease',
				delay: 1100
			});
			this.$initTexts.velocity({
				translateX: '0px',
				opacity: 1
			}, {
				duration: 1100,
				easing: 'ease',
				delay: 1100,
				complete: () => {
					this.hideOneText(this.$topTexts.eq(0));
					this.hideOneText(this.$botTexts.eq(0));
				}
			});
		}, 0);
	}

	showOneText($el) {
		$el.velocity({
			translateY: '0px',
			opacity: 1
		}, {
			duration: 1100,
			easing: [0.445, 0.05, 0.55, 0.95],
			complete: () => {
				this.hideOneText($el);
			}
		});
	}

	hideOneText($el) {
		const isTop = $el.data('side') == 'top';
		const translateValue = isTop ? '140px' : '-170px';

		$el.velocity({
			translateY: translateValue,
			opacity: [0, [0.445, 0.05, 0, 1.35], 1]
		}, {
			duration: 1100,
			easing: [0.445, 0.05, 0.55, 0.95],
			delay: 1600,
			complete: () => {
				const $arr = isTop ? this.$topTexts : this.$botTexts;

				this.showOneText(this.getNextInArr($arr, $el));
			}
		});
	}

	getNextInArr($arr, $el) {
		let $nextEl;

		$arr.each((i, el) => {
			if ($el[0] == el) {
				if (i == $arr.length - 1) {
					$nextEl = $arr.eq(0);
				} else {
					$nextEl = $arr.eq(i + 1);
				}
			}
		});

		return $nextEl;
	}

	checkSwipersStateBefore() {
		const currentSlide = this.swiper.slides[this.swiper.activeIndex];
		const prevSlide = this.swiper.slides[this.swiper.previousIndex];
		const subSliderWrapperCur = currentSlide.querySelector('.js-sub-slider-wrapper');
		const subSliderWrapperPrev = prevSlide && prevSlide.querySelector('.js-sub-slider-wrapper');

		if (subSliderWrapperPrev) {
			const subSlider = this.subSliders[prevSlide.dataset.slideId];

			if (subSlider.activeIndex == 0) {
				subSlider.allowSlideNext = false;
			} else {
				subSlider.allowSlidePrev = false;
			}
		} else if (subSliderWrapperCur) {
			const subSlider = this.subSliders[currentSlide.dataset.slideId];

			if (subSlider.activeIndex > 0 && subSlider.slides.length > subSlider.activeIndex + 1) {
				this.swiper.allowSlidePrev = false;
				this.swiper.allowSlideNext = false;
			} else if (subSlider.activeIndex == 0) {
				this.swiper.allowSlideNext = false;
			} else if (subSlider.slides.length == subSlider.activeIndex + 1) {
				this.swiper.allowSlidePrev = false;
			}
		}
	}

	checkSwipersStateAfter() {
		const currentSlide = this.swiper.slides[this.swiper.activeIndex];
		const prevSlide = this.swiper.slides[this.swiper.previousIndex];
		const subSliderWrapperCur = currentSlide.querySelector('.js-sub-slider-wrapper');
		const subSliderWrapperPrev = prevSlide && prevSlide.querySelector('.js-sub-slider-wrapper');

		if (subSliderWrapperCur) {
			const subSlider = this.subSliders[currentSlide.dataset.slideId];

			if (subSlider.activeIndex == 0) {
				this.swiper.allowSlidePrev = true;
				this.swiper.allowSlideNext = false;
				subSlider.allowSlidePrev = false;
				subSlider.allowSlideNext = true;
			} else if (subSlider.slides.length == subSlider.activeIndex + 1) {
				this.swiper.allowSlidePrev = false;
				this.swiper.allowSlideNext = true;
				subSlider.allowSlidePrev = true;
				subSlider.allowSlideNext = false;
			} else {
				this.swiper.allowSlidePrev = false;
				this.swiper.allowSlideNext = false;
				subSlider.allowSlidePrev = true;
				subSlider.allowSlideNext = true;
			}

		} else if (subSliderWrapperPrev) {
			const subSlider = this.subSliders[prevSlide.dataset.slideId];

			this.swiper.allowSlidePrev = true;
			this.swiper.allowSlideNext = true;
			subSlider.allowSlidePrev = false;
			subSlider.allowSlideNext = false;
		}

		setTimeout(() => {
			this.swiper.update();
			this.subSliders[1].update();

			if (subSliderWrapperCur) {
				$('.b-slide-text__title-main').eq(0).click().focus();
			}
		}, 100);
	}

	initSlidesLogic() {
		const $slides = this.swiper.slides;

		this.setInnerTranslates();

		$.each($slides, (key, slide) => {
			const $slide = $(slide);
			const slideID = $slide.attr('data-slide-id');
			const subSliderConfig = this.getSubSliderConfigByID(slideID);

			if (subSliderConfig) {
				const $sliderWrapper = $slide.find(subSliderConfig.sliderWrapperClass);

				if ($sliderWrapper.length) {
					if (subSliderConfig.options['pagination']) {
						subSliderConfig.options['pagination'] = this.getSubSliderPagination(subSliderConfig.options);

						if (subSliderConfig.options['pagination'].el) {
							subSliderConfig.options['pagination'].el = this.$sliderWrapper.find(subSliderConfig.options['pagination'].el);
						}
					}

					subSliderConfig.options['initialSlide'] = this.getSubInitial(slideID, $slide.find('.js-sub-slider-item').length);
					subSliderConfig.options['on'] = {
						slideChangeTransitionStart: () => {
							AR.events.emit('onSlideChangeTransitionStart_general');

							this.states.canSlide = false;
							const subSlider = this.subSliders[1];

							//переключение хеша в адресной строке
							let slideHash = this.getFullHashBySlideID(this.swiper.activeIndex, subSlider.activeIndex);
							if (slideHash) {
								this.Tools.setPageHash(slideHash);
							}

							// if (!this.touch) {
							this.showContentBefore(true);
							this.checkSwipersStateBefore();
							// }
						},
						slideChangeTransitionEnd: () => {
							this.states.canSlide = true;
							this.setAnimatedSlideState(false);

							// if (!this.touch) {
							this.hideContentAfter(true);
							this.checkSwipersStateAfter();
							// }
						}
					};

					this.initSubSlider($sliderWrapper, slideID, subSliderConfig.options);
					this.$subPagination = $(subSliderConfig.options['pagination'].el);
				}
			}
		});
	}

	getSubInitial(slideID, subSlidesLength) {
		const hash = window.location.hash.replace('#', '');
		const activeSlide = Array.from(this.swiper.slides).filter(slide => slide.dataset.slideHash == hash);

		if (activeSlide.length && activeSlide[0].dataset.slideId > slideID) {
			return subSlidesLength - 1;
		} else {
			return 0;
		}
	}

	setPaginationState() {
		const $currentSlide = $(this.swiper.slides[this.swiper.activeIndex]);

		if ($currentSlide.find('.js-sub-slider-wrapper').length) {
			this.$subPagination.addClass('is-active');
		} else if (this.$subPagination.hasClass('is-active')) {
			this.$subPagination.removeClass('is-active');
		}
	}

	getSubSliderConfigByID(id = 0) {
		const result = this.config.subSliders.filter((slider) => slider.parentSlideID == id);

		return (result.length) ? result[0] : false;
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_mainpage = new CSlider_mainpage();

	cSlider_mainpage.init();

	AR.pushComponent(cSlider_mainpage, 'cSlider_mainpage');
});
