module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'projects/projects' && templateVars.config ?
				templateVars.config :
				{
	"sort": [
		"years",
		"alphabet"
	],
	"casesPageName": "cases",
	"saveScrollPos": true,
	"scrollDuration": 800,
	"gridParams": {
		"class": "col-3 col-md-12 col-sm-12"
	},
	"breakpoints": {
		"lg": 1270,
		"md": 1054,
		"sm": 798,
		"xs": 510
	},
	"groups": [
		{
			"id": "annual-reports",
			"filter": [
				"annual-reports"
			],
			"title": {
				"ru": "Годовые отчеты",
				"en": "Годовые отчеты"
			}
		},
		{
			"id": "esg-csr",
			"filter": [
				"esg-csr"
			],
			"title": {
				"ru": "ESG, ОУР и&nbsp;КСО",
				"en": "ESG, ОУР и&nbsp;КСО"
			}
		},
		{
			"id": "online-reports",
			"filter": [
				"online-reports"
			],
			"title": {
				"ru": "Онлайн-отчеты",
				"en": "Онлайн-отчеты"
			}
		},
		{
			"id": "esef-ixbrl",
			"filter": [
				"esef-ixbrl"
			],
			"title": {
				"ru": "ESEF-отчеты (IXBRL)",
				"en": "ESEF-отчеты (IXBRL)"
			}
		}
	],
	"items": [
		{
			"id": 1,
			"title": {
				"ru": "X5 Retail Group",
				"en": "X5 Retail Group"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Фотосъемка",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Фотосъемка"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/x5_preview--2017.png",
				"md": "/images/content/ru/cases/x5_preview--2017.png",
				"sm": "/images/content/ru/cases/x5_preview--2017.png",
				"xl": "/images/content/ru/cases/x5_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/x5-2017"
		},
		{
			"id": 2,
			"title": {
				"ru": "Банк ВТБ",
				"en": "Банк ВТБ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Отчет КСО-2017<br>Интерактивный КСО-отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Отчет КСО-2017<br>Интерактивный КСО-отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/vtb_preview--2017.png",
				"md": "/images/content/ru/cases/vtb_preview--2017.png",
				"sm": "/images/content/ru/cases/vtb_preview--2017.png",
				"xl": "/images/content/ru/cases/vtb_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/vtb-2017"
		},
		{
			"id": 3,
			"title": {
				"ru": "GV GOLD Высочайший",
				"en": "GV GOLD Высочайший"
			},
			"years": [
				2017
			],
			"link": "cases/gvgold-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017",
				"en": "Годовой отчет-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/gv-gold_preview--2017.png",
				"md": "/images/content/ru/cases/gv-gold_preview--2017.png",
				"sm": "/images/content/ru/cases/gv-gold_preview--2017.png",
				"xl": "/images/content/ru/cases/gv-gold_preview--2017.png"
			}
		},
		{
			"id": 4,
			"title": {
				"ru": "Газпром нефть",
				"en": "Газпром нефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Отчет КСО-2017<br>Интерактивный КСО-отчет-2017<br>Презентация для ГОСА-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Отчет КСО-2017<br>Интерактивный КСО-отчет-2017<br>Презентация для ГОСА-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/gpn_preview--2017.png",
				"md": "/images/content/ru/cases/gpn_preview--2017.png",
				"sm": "/images/content/ru/cases/gpn_preview--2017.png",
				"xl": "/images/content/ru/cases/gpn_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/gazprom-neft-2017"
		},
		{
			"id": 5,
			"title": {
				"ru": "Департамент транспорта Москвы",
				"en": "Департамент транспорта Москвы"
			},
			"subTitle": {
				"ru": "Отчет о выполнении стратегии-2017<br>Интерактивный отчет-2017",
				"en": "Отчет о выполнении стратегии-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mosgortrans_preview--2017.png",
				"md": "/images/content/ru/cases/mosgortrans_preview--2017.png",
				"sm": "/images/content/ru/cases/mosgortrans_preview--2017.png",
				"xl": "/images/content/ru/cases/mosgortrans_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/mosgortrans-2017"
		},
		{
			"id": 6,
			"title": {
				"ru": "ЕВРАЗ",
				"en": "EVRAZ"
			},
			"years": [
				2017
			],
			"link": "cases/evraz-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/evraz_preview--2017.png",
				"md": "/images/content/ru/cases/evraz_preview--2017.png",
				"sm": "/images/content/ru/cases/evraz_preview--2017.png",
				"xl": "/images/content/ru/cases/evraz_preview--2017.png"
			}
		},
		{
			"id": 7,
			"title": {
				"ru": "Зарубежнефть",
				"en": "Зарубежнефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Отчет об&nbsp;устойчивом развитии-2017",
				"en": "Годовой отчет-2017<br>Отчет об&nbsp;устойчивом развитии-2017"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/zarubezhneft_preview--2017.png",
				"md": "/images/content/ru/cases/zarubezhneft_preview--2017.png",
				"sm": "/images/content/ru/cases/zarubezhneft_preview--2017.png",
				"xl": "/images/content/ru/cases/zarubezhneft_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/zarubezhneft-2017"
		},
		{
			"id": 8,
			"title": {
				"ru": "Ленэнерго",
				"en": "Ленэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Материалы ГОСА-2017",
				"en": "Годовой отчет-2017<br>Материалы ГОСА-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/lenenergo_preview--2017.png",
				"md": "/images/content/ru/cases/lenenergo_preview--2017.png",
				"sm": "/images/content/ru/cases/lenenergo_preview--2017.png",
				"xl": "/images/content/ru/cases/lenenergo_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/lenenergo-2017"
		},
		{
			"id": 9,
			"title": {
				"ru": "Лукойл",
				"en": "Лукойл"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Отчет об устойчивом развитии-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Отчет об устойчивом развитии-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/lukoil_preview--2017.png",
				"md": "/images/content/ru/cases/lukoil_preview--2017.png",
				"sm": "/images/content/ru/cases/lukoil_preview--2017.png",
				"xl": "/images/content/ru/cases/lukoil_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/lukoil-2017"
		},
		{
			"id": 10,
			"title": {
				"ru": "Мать и дитя",
				"en": "Мать и дитя"
			},
			"years": [
				2017
			],
			"link": "cases/mother-and-child-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017",
				"en": "Годовой отчет-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mother-and-child_preview--2017.png",
				"md": "/images/content/ru/cases/mother-and-child_preview--2017.png",
				"sm": "/images/content/ru/cases/mother-and-child_preview--2017.png",
				"xl": "/images/content/ru/cases/mother-and-child_preview--2017.png"
			}
		},
		{
			"id": 11,
			"title": {
				"ru": "Московская Биржа",
				"en": "Московская Биржа"
			},
			"years": [
				2017
			],
			"link": "cases/moex-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mosbirzha_preview--2017.png",
				"md": "/images/content/ru/cases/mosbirzha_preview--2017.png",
				"sm": "/images/content/ru/cases/mosbirzha_preview--2017.png",
				"xl": "/images/content/ru/cases/mosbirzha_preview--2017.png"
			}
		},
		{
			"id": 12,
			"title": {
				"ru": "МРСК Центра",
				"en": "МРСК Центра"
			},
			"years": [
				2017
			],
			"link": "cases/mrsk-1-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017",
				"en": "Годовой отчет-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_c_preview--2017.png",
				"md": "/images/content/ru/cases/mrsk_c_preview--2017.png",
				"sm": "/images/content/ru/cases/mrsk_c_preview--2017.png",
				"xl": "/images/content/ru/cases/mrsk_c_preview--2017.png"
			}
		},
		{
			"id": 13,
			"title": {
				"ru": "МРСК Центра и Приволжья",
				"en": "МРСК Центра и Приволжья"
			},
			"years": [
				2017
			],
			"link": "cases/mrsk-cp-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017",
				"en": "Годовой отчет-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_cp_preview--2017.png",
				"md": "/images/content/ru/cases/mrsk_cp_preview--2017.png",
				"sm": "/images/content/ru/cases/mrsk_cp_preview--2017.png",
				"xl": "/images/content/ru/cases/mrsk_cp_preview--2017.png"
			}
		},
		{
			"id": 14,
			"title": {
				"ru": "МРСК Юга",
				"en": "МРСК Юга"
			},
			"years": [
				2017
			],
			"link": "cases/mrsk-yuga-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_yuga_preview--2017.png",
				"md": "/images/content/ru/cases/mrsk_yuga_preview--2017.png",
				"sm": "/images/content/ru/cases/mrsk_yuga_preview--2017.png",
				"xl": "/images/content/ru/cases/mrsk_yuga_preview--2017.png"
			}
		},
		{
			"id": 15,
			"title": {
				"ru": "НМТП",
				"en": "НМТП"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/ncsp_preview--2017.png",
				"md": "/images/content/ru/cases/ncsp_preview--2017.png",
				"sm": "/images/content/ru/cases/ncsp_preview--2017.png",
				"xl": "/images/content/ru/cases/ncsp_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/nmtp-2017"
		},
		{
			"id": 16,
			"title": {
				"ru": "Норильский никель",
				"en": "Норильский никель"
			},
			"years": [
				2017
			],
			"link": "cases/nornickel-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nornickel_preview--2017.png",
				"md": "/images/content/ru/cases/nornickel_preview--2017.png",
				"sm": "/images/content/ru/cases/nornickel_preview--2017.png",
				"xl": "/images/content/ru/cases/nornickel_preview--2017.png"
			}
		},
		{
			"id": 17,
			"title": {
				"ru": "РВК",
				"en": "РВК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Sustainability Report-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rvc_preview--2017.png",
				"md": "/images/content/ru/cases/rvc_preview--2017.png",
				"sm": "/images/content/ru/cases/rvc_preview--2017.png",
				"xl": "/images/content/ru/cases/rvc_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/rvc-2017"
		},
		{
			"id": 18,
			"title": {
				"ru": "РЖД",
				"en": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rzd_preview--2017.png",
				"md": "/images/content/ru/cases/rzd_preview--2017.png",
				"sm": "/images/content/ru/cases/rzd_preview--2017.png",
				"xl": "/images/content/ru/cases/rzd_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/rzd-2017"
		},
		{
			"id": 19,
			"title": {
				"ru": "Роснефть",
				"en": "Роснефть"
			},
			"years": [
				2017
			],
			"link": "cases/rosneft-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Материалы ГОСА-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Материалы ГОСА-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rosneft_preview--2017.png",
				"md": "/images/content/ru/cases/rosneft_preview--2017.png",
				"sm": "/images/content/ru/cases/rosneft_preview--2017.png",
				"xl": "/images/content/ru/cases/rosneft_preview--2017.png"
			}
		},
		{
			"id": 20,
			"title": {
				"ru": "РусГидро",
				"en": "РусГидро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Презентация для ГОСА-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017<br>Презентация для ГОСА-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusgidro_preview--2017.png",
				"md": "/images/content/ru/cases/rusgidro_preview--2017.png",
				"sm": "/images/content/ru/cases/rusgidro_preview--2017.png",
				"xl": "/images/content/ru/cases/rusgidro_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/rushydro-2017"
		},
		{
			"id": 21,
			"title": {
				"ru": "Сбербанк",
				"en": "Sberbank"
			},
			"years": [
				2017
			],
			"link": "cases/sberbank-2017",
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/sber_preview--2017.png",
				"md": "/images/content/ru/cases/sber_preview--2017.png",
				"sm": "/images/content/ru/cases/sber_preview--2017.png",
				"xl": "/images/content/ru/cases/sber_preview--2017.png"
			}
		},
		{
			"id": 22,
			"title": {
				"ru": "СУЭК",
				"en": "СУЭК"
			},
			"subTitle": {
				"ru": "Интерактивный отчет-2017",
				"en": "Интерактивный отчет-2017"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/suek_preview--2017.png",
				"md": "/images/content/ru/cases/suek_preview--2017.png",
				"sm": "/images/content/ru/cases/suek_preview--2017.png",
				"xl": "/images/content/ru/cases/suek_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/suek-2017"
		},
		{
			"id": 23,
			"title": {
				"ru": "Трансконтейнер",
				"en": "Трансконтейнер"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017",
				"en": "Годовой отчет-2017"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/transkonteiner_preview--2017.png",
				"md": "/images/content/ru/cases/transkonteiner_preview--2017.png",
				"sm": "/images/content/ru/cases/transkonteiner_preview--2017.png",
				"xl": "/images/content/ru/cases/transkonteiner_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/transkonteiner-2017"
		},
		{
			"id": 24,
			"title": {
				"ru": "ФПК",
				"en": "ФПК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2017<br>Интерактивный отчет-2017",
				"en": "Годовой отчет-2017<br>Интерактивный отчет-2017"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/fpk_preview--2017.png",
				"md": "/images/content/ru/cases/fpk_preview--2017.png",
				"sm": "/images/content/ru/cases/fpk_preview--2017.png",
				"xl": "/images/content/ru/cases/fpk_preview--2017.png"
			},
			"years": [
				2017
			],
			"link": "cases/fpk-2017"
		},
		{
			"id": 25,
			"title": {
				"ru": "X5 Retail Group",
				"en": "X5 Retail Group"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/x5_preview--2018.png",
				"md": "/images/content/ru/cases/x5_preview--2018.png",
				"sm": "/images/content/ru/cases/x5_preview--2018.png",
				"xl": "/images/content/ru/cases/x5_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/x5-2018"
		},
		{
			"id": 26,
			"title": {
				"ru": "GV GOLD Высочайший",
				"en": "GV GOLD"
			},
			"years": [
				2018
			],
			"link": "cases/gvgold-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/gv-gold_preview--2018.png",
				"md": "/images/content/ru/cases/gv-gold_preview--2018.png",
				"sm": "/images/content/ru/cases/gv-gold_preview--2018.png",
				"xl": "/images/content/ru/cases/gv-gold_preview--2018.png"
			}
		},
		{
			"id": 27,
			"title": {
				"ru": "ФПК",
				"en": "ФПК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/fpk_preview--2018.png",
				"md": "/images/content/ru/cases/fpk_preview--2018.png",
				"sm": "/images/content/ru/cases/fpk_preview--2018.png",
				"xl": "/images/content/ru/cases/fpk_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/fpk-2018"
		},
		{
			"id": 28,
			"title": {
				"ru": "Трансконтейнер",
				"en": "Трансконтейнер"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/transkonteiner_preview--2018.png",
				"md": "/images/content/ru/cases/transkonteiner_preview--2018.png",
				"sm": "/images/content/ru/cases/transkonteiner_preview--2018.png",
				"xl": "/images/content/ru/cases/transkonteiner_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/transkonteiner-2018"
		},
		{
			"id": 29,
			"title": {
				"ru": "СУЭК",
				"en": "СУЭК"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2018<br>Интерактивный отчет-2018",
				"en": "Интегрированный отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/suek_preview--2018.png",
				"md": "/images/content/ru/cases/suek_preview--2018.png",
				"sm": "/images/content/ru/cases/suek_preview--2018.png",
				"xl": "/images/content/ru/cases/suek_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/suek-2018"
		},
		{
			"id": 30,
			"title": {
				"ru": "En+",
				"en": "En+"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/enplus_preview--2018.png",
				"md": "/images/content/ru/cases/enplus_preview--2018.png",
				"sm": "/images/content/ru/cases/enplus_preview--2018.png",
				"xl": "/images/content/ru/cases/enplus_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/enplus-2018"
		},
		{
			"id": 31,
			"title": {
				"ru": "ERG",
				"en": "ERG"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/erg_preview--2018.png",
				"md": "/images/content/ru/cases/erg_preview--2018.png",
				"sm": "/images/content/ru/cases/erg_preview--2018.png",
				"xl": "/images/content/ru/cases/erg_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/erg-2018"
		},
		{
			"id": 32,
			"title": {
				"ru": "Транснефть",
				"en": "Транснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/transneft_preview--2018.png",
				"md": "/images/content/ru/cases/transneft_preview--2018.png",
				"sm": "/images/content/ru/cases/transneft_preview--2018.png",
				"xl": "/images/content/ru/cases/transneft_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/transneft-2018"
		},
		{
			"id": 33,
			"title": {
				"ru": "РусГидро",
				"en": "РусГидро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Презентация для ГОСА-2018<br>Брошюры и материалы",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Презентация для ГОСА-2018<br>Брошюры и материалы"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusgidro_preview--2018.png",
				"md": "/images/content/ru/cases/rusgidro_preview--2018.png",
				"sm": "/images/content/ru/cases/rusgidro_preview--2018.png",
				"xl": "/images/content/ru/cases/rusgidro_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/rushydro-2018"
		},
		{
			"id": 34,
			"title": {
				"ru": "Русагро",
				"en": "Rusagro"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusagro_preview--2018.png",
				"md": "/images/content/ru/cases/rusagro_preview--2018.png",
				"sm": "/images/content/ru/cases/rusagro_preview--2018.png",
				"xl": "/images/content/ru/cases/rusagro_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/rusagro-2018"
		},
		{
			"id": 35,
			"title": {
				"ru": "РЖД",
				"en": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Фотосъемка",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Фотосъемка"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rzd_preview--2018.png",
				"md": "/images/content/ru/cases/rzd_preview--2018.png",
				"sm": "/images/content/ru/cases/rzd_preview--2018.png",
				"xl": "/images/content/ru/cases/rzd_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/rzd-2018"
		},
		{
			"id": 36,
			"title": {
				"ru": "Роснефть",
				"en": "Роснефть"
			},
			"years": [
				2018
			],
			"link": "cases/rosneft-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Брошюры и&nbsp;материалы",
				"en": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Брошюры и&nbsp;материалы"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/rosneft_preview--2018.png",
				"md": "/images/content/ru/cases/rosneft_preview--2018.png",
				"sm": "/images/content/ru/cases/rosneft_preview--2018.png",
				"xl": "/images/content/ru/cases/rosneft_preview--2018.png"
			}
		},
		{
			"id": 37,
			"title": {
				"ru": "Ростелеком",
				"en": "Rostelecom"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/rostelecom_preview--2018.png",
				"md": "/images/content/ru/cases/rostelecom_preview--2018.png",
				"sm": "/images/content/ru/cases/rostelecom_preview--2018.png",
				"xl": "/images/content/ru/cases/rostelecom_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/rostelecom-2018"
		},
		{
			"id": 38,
			"title": {
				"ru": "РВК",
				"en": "РВК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Sustainability Report-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rvc_preview--2018.png",
				"md": "/images/content/ru/cases/rvc_preview--2018.png",
				"sm": "/images/content/ru/cases/rvc_preview--2018.png",
				"xl": "/images/content/ru/cases/rvc_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/rvc-2018"
		},
		{
			"id": 39,
			"title": {
				"ru": "Открытие",
				"en": "Открытие"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Дополнительные материалы",
				"en": "Годовой отчет-2018<br>Дополнительные материалы"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/open_preview--2018.png",
				"md": "/images/content/ru/cases/open_preview--2018.png",
				"sm": "/images/content/ru/cases/open_preview--2018.png",
				"xl": "/images/content/ru/cases/open_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/open-2018"
		},
		{
			"id": 40,
			"title": {
				"ru": "ОВК",
				"en": "OVK"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/ovk_preview--2018.png",
				"md": "/images/content/ru/cases/ovk_preview--2018.png",
				"sm": "/images/content/ru/cases/ovk_preview--2018.png",
				"xl": "/images/content/ru/cases/ovk_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/ovk-2018"
		},
		{
			"id": 41,
			"title": {
				"ru": "Норильский никель",
				"en": "Норильский никель"
			},
			"years": [
				2018
			],
			"link": "cases/nornickel-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Буклет для инвесторов-2018<br>Материалы к&nbsp;годовому отчету собранию акционеров-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Буклет для инвесторов-2018<br>Материалы к&nbsp;годовому отчету собранию акционеров-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nornickel_preview--2018.png",
				"md": "/images/content/ru/cases/nornickel_preview--2018.png",
				"sm": "/images/content/ru/cases/nornickel_preview--2018.png",
				"xl": "/images/content/ru/cases/nornickel_preview--2018.png"
			}
		},
		{
			"id": 42,
			"title": {
				"ru": "Новикомбанк",
				"en": "Новикомбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/novikombank_preview--2018.png",
				"md": "/images/content/ru/cases/novikombank_preview--2018.png",
				"sm": "/images/content/ru/cases/novikombank_preview--2018.png",
				"xl": "/images/content/ru/cases/novikombank_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/novikombank-2018"
		},
		{
			"id": 43,
			"title": {
				"ru": "МРСК Сибири",
				"en": "МРСК Сибири"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk-sib_preview--2018.png",
				"md": "/images/content/ru/cases/mrsk-sib_preview--2018.png",
				"sm": "/images/content/ru/cases/mrsk-sib_preview--2018.png",
				"xl": "/images/content/ru/cases/mrsk-sib_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/mrsk-sib-2018"
		},
		{
			"id": 44,
			"title": {
				"ru": "МРСК Центра",
				"en": "МРСК Центра"
			},
			"years": [
				2018
			],
			"link": "cases/mrsk-1-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_c_preview--2018.png",
				"md": "/images/content/ru/cases/mrsk_c_preview--2018.png",
				"sm": "/images/content/ru/cases/mrsk_c_preview--2018.png",
				"xl": "/images/content/ru/cases/mrsk_c_preview--2018.png"
			}
		},
		{
			"id": 45,
			"title": {
				"ru": "Мать и дитя",
				"en": "Мать и дитя"
			},
			"years": [
				2018
			],
			"link": "cases/mother-and-child-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018",
				"en": "Годовой отчет-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mother-and-child_preview--2018.png",
				"md": "/images/content/ru/cases/mother-and-child_preview--2018.png",
				"sm": "/images/content/ru/cases/mother-and-child_preview--2018.png",
				"xl": "/images/content/ru/cases/mother-and-child_preview--2018.png"
			}
		},
		{
			"id": 46,
			"title": {
				"ru": "МРСК Юга",
				"en": "МРСК Юга"
			},
			"years": [
				2018
			],
			"link": "cases/mrsk-yuga-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_yuga_preview--2018.png",
				"md": "/images/content/ru/cases/mrsk_yuga_preview--2018.png",
				"sm": "/images/content/ru/cases/mrsk_yuga_preview--2018.png",
				"xl": "/images/content/ru/cases/mrsk_yuga_preview--2018.png"
			}
		},
		{
			"id": 47,
			"title": {
				"ru": "Магнит",
				"en": "Magnit"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/magnit_preview--2018.png",
				"md": "/images/content/ru/cases/magnit_preview--2018.png",
				"sm": "/images/content/ru/cases/magnit_preview--2018.png",
				"xl": "/images/content/ru/cases/magnit_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/magnit-2018"
		},
		{
			"id": 48,
			"title": {
				"ru": "М.Видео-Эльдорадо",
				"en": "М.Видео-Эльдорадо"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mvideo_preview--2018.png",
				"md": "/images/content/ru/cases/mvideo_preview--2018.png",
				"sm": "/images/content/ru/cases/mvideo_preview--2018.png",
				"xl": "/images/content/ru/cases/mvideo_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/mvideo-eldorado-2018"
		},
		{
			"id": 49,
			"title": {
				"ru": "Московская Биржа",
				"en": "Московская Биржа"
			},
			"years": [
				2018
			],
			"link": "cases/moex-2018",
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mosbirzha_preview--2018.png",
				"md": "/images/content/ru/cases/mosbirzha_preview--2018.png",
				"sm": "/images/content/ru/cases/mosbirzha_preview--2018.png",
				"xl": "/images/content/ru/cases/mosbirzha_preview--2018.png"
			}
		},
		{
			"id": 50,
			"title": {
				"ru": "Ленэнерго",
				"en": "Ленэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Материалы ГОСА-2018",
				"en": "Годовой отчет-2018<br>Материалы ГОСА-2018"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/lenenergo_preview--2018.png",
				"md": "/images/content/ru/cases/lenenergo_preview--2018.png",
				"sm": "/images/content/ru/cases/lenenergo_preview--2018.png",
				"xl": "/images/content/ru/cases/lenenergo_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/lenenergo-2018"
		},
		{
			"id": 51,
			"title": {
				"ru": "Лукойл",
				"en": "Лукойл"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018<br>Дополнительные материалы",
				"en": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018<br>Дополнительные материалы"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/lukoil_preview--2018.png",
				"md": "/images/content/ru/cases/lukoil_preview--2018.png",
				"sm": "/images/content/ru/cases/lukoil_preview--2018.png",
				"xl": "/images/content/ru/cases/lukoil_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/lukoil-2018"
		},
		{
			"id": 52,
			"title": {
				"ru": "Кубаньэнерго",
				"en": "Кубаньэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/kubanenergo_preview--2018.png",
				"md": "/images/content/ru/cases/kubanenergo_preview--2018.png",
				"sm": "/images/content/ru/cases/kubanenergo_preview--2018.png",
				"xl": "/images/content/ru/cases/kubanenergo_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/kubanenergo-2018"
		},
		{
			"id": 53,
			"title": {
				"ru": "Интер РАО",
				"en": "Inter RAO"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Презентация для&nbsp;ГОСА-2018",
				"en": "Годовой отчет-2018<br>Интерактивный отчет-2018<br>Презентация для&nbsp;ГОСА-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/interrao_preview--2018.png",
				"md": "/images/content/ru/cases/interrao_preview--2018.png",
				"sm": "/images/content/ru/cases/interrao_preview--2018.png",
				"xl": "/images/content/ru/cases/interrao_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/interrao-2018"
		},
		{
			"id": 54,
			"title": {
				"ru": "Зарубежнефть",
				"en": "Зарубежнефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Отчет об устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018<br>Дополнительные материалы",
				"en": "Годовой отчет-2018<br>Отчет об устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018<br>Дополнительные материалы"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/zarubezhneft_preview--2018.png",
				"md": "/images/content/ru/cases/zarubezhneft_preview--2018.png",
				"sm": "/images/content/ru/cases/zarubezhneft_preview--2018.png",
				"xl": "/images/content/ru/cases/zarubezhneft_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/zarubezhneft-2018"
		},
		{
			"id": 55,
			"title": {
				"ru": "ЕВРАЗ",
				"en": "EVRAZ"
			},
			"years": [
				2018
			],
			"link": "cases/evraz-2018",
			"subTitle": {
				"ru": "Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018",
				"en": "Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/evraz_preview--2018.png",
				"md": "/images/content/ru/cases/evraz_preview--2018.png",
				"sm": "/images/content/ru/cases/evraz_preview--2018.png",
				"xl": "/images/content/ru/cases/evraz_preview--2018.png"
			}
		},
		{
			"id": 56,
			"title": {
				"ru": "Газпром нефть",
				"en": "Газпром нефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018",
				"en": "Годовой отчет-2018<br>Отчет об&nbsp;устойчивом развитии-2018<br>Интерактивный отчет-2018<br>Интерактивный отчет об&nbsp;устойчивом развитии-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/gpn_preview--2018.png",
				"md": "/images/content/ru/cases/gpn_preview--2018.png",
				"sm": "/images/content/ru/cases/gpn_preview--2018.png",
				"xl": "/images/content/ru/cases/gpn_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/gazprom-neft-2018"
		},
		{
			"id": 57,
			"title": {
				"ru": "Банк ВТБ",
				"en": "Банк ВТБ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2018<br>Интерактивный годовой отчет-2018",
				"en": "Годовой отчет-2018<br>Интерактивный годовой отчет-2018"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/vtb_preview--2018.png",
				"md": "/images/content/ru/cases/vtb_preview--2018.png",
				"sm": "/images/content/ru/cases/vtb_preview--2018.png",
				"xl": "/images/content/ru/cases/vtb_preview--2018.png"
			},
			"years": [
				2018
			],
			"link": "cases/vtb-2018"
		},
		{
			"id": 58,
			"title": {
				"ru": "En+",
				"en": "En+"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>ESEF-Compliance",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/enplus_preview--2019.png",
				"md": "/images/content/ru/cases/enplus_preview--2019.png",
				"sm": "/images/content/ru/cases/enplus_preview--2019.png",
				"xl": "/images/content/ru/cases/enplus_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/enplus-2019"
		},
		{
			"id": 59,
			"title": {
				"ru": "ЕВРАЗ",
				"en": "ЕВРАЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019<br>ESEF-Compliance<br>Презентация результатов года",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019<br>ESEF-Compliance<br>Презентация результатов года"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/evraz_preview--2019.png",
				"md": "/images/content/ru/cases/evraz_preview--2019.png",
				"sm": "/images/content/ru/cases/evraz_preview--2019.png",
				"xl": "/images/content/ru/cases/evraz_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/evraz-2019"
		},
		{
			"id": 60,
			"title": {
				"ru": "Газпром нефть",
				"en": "Газпром нефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/gazprom-neft-preview--2019.png",
				"md": "/images/content/ru/cases/gazprom-neft-preview--2019.png",
				"sm": "/images/content/ru/cases/gazprom-neft-preview--2019.png",
				"xl": "/images/content/ru/cases/gazprom-neft-preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/gazprom-neft-2019"
		},
		{
			"id": 61,
			"title": {
				"ru": "МегаФон",
				"en": "МегаФон"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/megafon_preview--2019.png",
				"md": "/images/content/ru/cases/megafon_preview--2019.png",
				"sm": "/images/content/ru/cases/megafon_preview--2019.png",
				"xl": "/images/content/ru/cases/megafon_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/megafon-2019"
		},
		{
			"id": 62,
			"title": {
				"ru": "Интер РАО",
				"en": "Интер РАО"
			},
			"subTitle": {
				"ru": "Интегрированый годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>",
				"en": "Интегрированый годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/interrao-preview--2019.png",
				"md": "/images/content/ru/cases/interrao-preview--2019.png",
				"sm": "/images/content/ru/cases/interrao-preview--2019.png",
				"xl": "/images/content/ru/cases/interrao-preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/interrao-2019"
		},
		{
			"id": 63,
			"title": {
				"ru": "Лукойл",
				"en": "Лукойл"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/lukoil_preview--2019.png",
				"md": "/images/content/ru/cases/lukoil_preview--2019.png",
				"sm": "/images/content/ru/cases/lukoil_preview--2019.png",
				"xl": "/images/content/ru/cases/lukoil_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/lukoil-2019"
		},
		{
			"id": 64,
			"title": {
				"ru": "Московская Биржа",
				"en": "Московская Биржа"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/mosbirzha_preview--2019.png",
				"md": "/images/content/ru/cases/mosbirzha_preview--2019.png",
				"sm": "/images/content/ru/cases/mosbirzha_preview--2019.png",
				"xl": "/images/content/ru/cases/mosbirzha_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/moex-2019"
		},
		{
			"id": 65,
			"title": {
				"ru": "МРСК Центра",
				"en": "МРСК Центра"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2019",
				"en": "Интегрированный годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_c_preview--2019.png",
				"md": "/images/content/ru/cases/mrsk_c_preview--2019.png",
				"sm": "/images/content/ru/cases/mrsk_c_preview--2019.png",
				"xl": "/images/content/ru/cases/mrsk_c_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/mrsk-c-2019"
		},
		{
			"id": 66,
			"title": {
				"ru": "МРСК Центра и Приволжья",
				"en": "МРСК Центра и Приволжья"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2019",
				"en": "Интегрированный годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_cp_preview--2019.png",
				"md": "/images/content/ru/cases/mrsk_cp_preview--2019.png",
				"sm": "/images/content/ru/cases/mrsk_cp_preview--2019.png",
				"xl": "/images/content/ru/cases/mrsk_cp_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/mrsk-cp-2019"
		},
		{
			"id": 67,
			"title": {
				"ru": "Россети Юг",
				"en": "Россети Юг"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2019",
				"en": "Интегрированный годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk_yuga_preview--2019.png",
				"md": "/images/content/ru/cases/mrsk_yuga_preview--2019.png",
				"sm": "/images/content/ru/cases/mrsk_yuga_preview--2019.png",
				"xl": "/images/content/ru/cases/mrsk_yuga_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/rosseti-s-2019"
		},
		{
			"id": 68,
			"title": {
				"ru": "Мать и дитя",
				"en": "Мать и дитя"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mother-and-child_preview--2019.png",
				"md": "/images/content/ru/cases/mother-and-child_preview--2019.png",
				"sm": "/images/content/ru/cases/mother-and-child_preview--2019.png",
				"xl": "/images/content/ru/cases/mother-and-child_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/mother-and-child-2019"
		},
		{
			"id": 69,
			"title": {
				"ru": "НЛМК",
				"en": "НЛМК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nlmk_preview--2019.png",
				"md": "/images/content/ru/cases/nlmk_preview--2019.png",
				"sm": "/images/content/ru/cases/nlmk_preview--2019.png",
				"xl": "/images/content/ru/cases/nlmk_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/nlmk-2019"
		},
		{
			"id": 70,
			"title": {
				"ru": "НМТП",
				"en": "НМТП"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nmtp_preview--2019.png",
				"md": "/images/content/ru/cases/nmtp_preview--2019.png",
				"sm": "/images/content/ru/cases/nmtp_preview--2019.png",
				"xl": "/images/content/ru/cases/nmtp_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/nmtp-2019"
		},
		{
			"id": 70,
			"title": {
				"ru": "Норникель",
				"en": "Норникель"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/nornickel_preview--2019.png",
				"md": "/images/content/ru/cases/nornickel_preview--2019.png",
				"sm": "/images/content/ru/cases/nornickel_preview--2019.png",
				"xl": "/images/content/ru/cases/nornickel_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/nornickel-2019"
		},
		{
			"id": 71,
			"title": {
				"ru": "ВТБ",
				"en": "ВТБ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019<br>ESEF-Compliance",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/vtb_preview--2019.png",
				"md": "/images/content/ru/cases/vtb_preview--2019.png",
				"sm": "/images/content/ru/cases/vtb_preview--2019.png",
				"xl": "/images/content/ru/cases/vtb_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/vtb-2019"
		},
		{
			"id": 72,
			"title": {
				"ru": "Зарубежнефть",
				"en": "Зарубежнефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/zarubezhneft_preview--2019.png",
				"md": "/images/content/ru/cases/zarubezhneft_preview--2019.png",
				"sm": "/images/content/ru/cases/zarubezhneft_preview--2019.png",
				"xl": "/images/content/ru/cases/zarubezhneft_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/zarubezhneft-2019"
		},
		{
			"id": 73,
			"title": {
				"ru": "Казахтелеком",
				"en": "Казахтелеком"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2019",
				"en": "Интегрированный годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/kazakhtelecom_preview--2019.png",
				"md": "/images/content/ru/cases/kazakhtelecom_preview--2019.png",
				"sm": "/images/content/ru/cases/kazakhtelecom_preview--2019.png",
				"xl": "/images/content/ru/cases/kazakhtelecom_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/kazakhtelecom-2019"
		},
		{
			"id": 74,
			"title": {
				"ru": "КазМунайГаз",
				"en": "КазМунайГаз"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/kmg_preview--2019.png",
				"md": "/images/content/ru/cases/kmg_preview--2019.png",
				"sm": "/images/content/ru/cases/kmg_preview--2019.png",
				"xl": "/images/content/ru/cases/kmg_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/kmg-2019"
		},
		{
			"id": 75,
			"title": {
				"ru": "ОТЛК ЕРА",
				"en": "ОТЛК ЕРА"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/otlk-era_preview--2019.png",
				"md": "/images/content/ru/cases/otlk-era_preview--2019.png",
				"sm": "/images/content/ru/cases/otlk-era_preview--2019.png",
				"xl": "/images/content/ru/cases/otlk-era_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/otlk-era-2019"
		},
		{
			"id": 76,
			"title": {
				"en": "Почта России",
				"ru": "Почта России"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/russian-post_preview--2019.png",
				"md": "/images/content/ru/cases/russian-post_preview--2019.png",
				"sm": "/images/content/ru/cases/russian-post_preview--2019.png",
				"xl": "/images/content/ru/cases/russian-post_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/russian-post-2019"
		},
		{
			"id": 77,
			"title": {
				"en": "РЖД",
				"ru": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rzd_preview--2019.png",
				"md": "/images/content/ru/cases/rzd_preview--2019.png",
				"sm": "/images/content/ru/cases/rzd_preview--2019.png",
				"xl": "/images/content/ru/cases/rzd_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/rzd-2019"
		},
		{
			"id": 78,
			"title": {
				"en": "МТС",
				"ru": "МТС"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/mts_preview--2019.png",
				"md": "/images/content/ru/cases/mts_preview--2019.png",
				"sm": "/images/content/ru/cases/mts_preview--2019.png",
				"xl": "/images/content/ru/cases/mts_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/mts-2019"
		},
		{
			"id": 79,
			"title": {
				"en": "Корпоративный университет РЖД",
				"ru": "Корпоративный университет РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rzd-u_preview--2019.png",
				"md": "/images/content/ru/cases/rzd-u_preview--2019.png",
				"sm": "/images/content/ru/cases/rzd-u_preview--2019.png",
				"xl": "/images/content/ru/cases/rzd-u_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/corp-university-rzd-2019"
		},
		{
			"id": 80,
			"title": {
				"en": "Роснефть",
				"ru": "Роснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019",
				"en": "Годовой отчет-2019<br>Отчет об&nbsp;устойчивом развитии-2019<br>Интерактивный годовой отчет-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/rosneft_preview--2019.png",
				"md": "/images/content/ru/cases/rosneft_preview--2019.png",
				"sm": "/images/content/ru/cases/rosneft_preview--2019.png",
				"xl": "/images/content/ru/cases/rosneft_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/rosneft-2019"
		},
		{
			"id": 81,
			"title": {
				"en": "Русагро",
				"ru": "Русагро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusagro_preview--2019.png",
				"md": "/images/content/ru/cases/rusagro_preview--2019.png",
				"sm": "/images/content/ru/cases/rusagro_preview--2019.png",
				"xl": "/images/content/ru/cases/rusagro_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/rusagro-2019"
		},
		{
			"id": 82,
			"title": {
				"en": "Совкомфлот",
				"ru": "Совкомфлот"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/scf_preview--2019.png",
				"md": "/images/content/ru/cases/scf_preview--2019.png",
				"sm": "/images/content/ru/cases/scf_preview--2019.png",
				"xl": "/images/content/ru/cases/scf_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/scf-group-2019"
		},
		{
			"id": 83,
			"title": {
				"en": "СУЭК",
				"ru": "СУЭК"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2019<br>Интерактивный интегрированный отчет-2019<br>Корпоративная брошюра",
				"en": "Интегрированный отчет-2019<br>Интерактивный интегрированный отчет-2019<br>Корпоративная брошюра"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/suek_preview--2019.png",
				"md": "/images/content/ru/cases/suek_preview--2019.png",
				"sm": "/images/content/ru/cases/suek_preview--2019.png",
				"xl": "/images/content/ru/cases/suek_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/suek-2019"
		},
		{
			"id": 84,
			"title": {
				"en": "Федеральная грузовая компания",
				"ru": "Федеральная грузовая компания"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/fgc_preview--2019.png",
				"md": "/images/content/ru/cases/fgc_preview--2019.png",
				"sm": "/images/content/ru/cases/fgc_preview--2019.png",
				"xl": "/images/content/ru/cases/fgc_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/fgc-2019"
		},
		{
			"id": 85,
			"title": {
				"en": "ФосАгро",
				"ru": "ФосАгро"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance",
				"en": "Интегрированный отчет-2019<br>Интерактивный годовой отчет-2019<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/phosagro_preview--2019.png",
				"md": "/images/content/ru/cases/phosagro_preview--2019.png",
				"sm": "/images/content/ru/cases/phosagro_preview--2019.png",
				"xl": "/images/content/ru/cases/phosagro_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/phosagro-2019"
		},
		{
			"id": 86,
			"title": {
				"en": "Федеральная пассажирская компания",
				"ru": "Федеральная пассажирская компания"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019",
				"en": "Годовой отчет-2019<br>Интерактивный годовой отчет-2019"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/fpc_preview--2019.png",
				"md": "/images/content/ru/cases/fpc_preview--2019.png",
				"sm": "/images/content/ru/cases/fpc_preview--2019.png",
				"xl": "/images/content/ru/cases/fpc_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/fpc-2019"
		},
		{
			"id": 87,
			"title": {
				"en": "ЧТПЗ",
				"ru": "ЧТПЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/chtpz_preview--2019.png",
				"md": "/images/content/ru/cases/chtpz_preview--2019.png",
				"sm": "/images/content/ru/cases/chtpz_preview--2019.png",
				"xl": "/images/content/ru/cases/chtpz_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/chtpz-2019"
		},
		{
			"id": 87,
			"title": {
				"en": "ЦОДД",
				"ru": "ЦОДД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2019",
				"en": "Годовой отчет-2019"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/codd_preview--2019.png",
				"md": "/images/content/ru/cases/codd_preview--2019.png",
				"sm": "/images/content/ru/cases/codd_preview--2019.png",
				"xl": "/images/content/ru/cases/codd_preview--2019.png"
			},
			"years": [
				2019
			],
			"link": "cases/codd-2019"
		},
		{
			"id": 88,
			"title": {
				"en": "АФК «Система»",
				"ru": "АФК «Система»"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/afk_preview--2020.png",
				"md": "/images/content/ru/cases/afk_preview--2020.png",
				"sm": "/images/content/ru/cases/afk_preview--2020.png",
				"xl": "/images/content/ru/cases/afk_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/afk-2020"
		},
		{
			"id": 89,
			"title": {
				"en": "ЧТПЗ",
				"ru": "ЧТПЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/chtpz_preview--2020.png",
				"md": "/images/content/ru/cases/chtpz_preview--2020.png",
				"sm": "/images/content/ru/cases/chtpz_preview--2020.png",
				"xl": "/images/content/ru/cases/chtpz_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/chtpz-2020"
		},
		{
			"id": 90,
			"title": {
				"en": "Россети Кубань",
				"ru": "Россети Кубань"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk-cuban_preview--2020.png",
				"md": "/images/content/ru/cases/mrsk-cuban_preview--2020.png",
				"sm": "/images/content/ru/cases/mrsk-cuban_preview--2020.png",
				"xl": "/images/content/ru/cases/mrsk-cuban_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/mrsk-cuban-2020"
		},
		{
			"id": 91,
			"title": {
				"en": "Газпром-медиа",
				"ru": "Газпром-медиа"
			},
			"subTitle": {
				"ru": "Интерактивный годовой отчет-2020",
				"en": "Интерактивный годовой отчет-2020"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/gpn-media_preview--2020.png",
				"md": "/images/content/ru/cases/gpn-media_preview--2020.png",
				"sm": "/images/content/ru/cases/gpn-media_preview--2020.png",
				"xl": "/images/content/ru/cases/gpn-media_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/gpn-media-2020"
		},
		{
			"id": 92,
			"title": {
				"en": "Газпром нефть",
				"ru": "Газпром нефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020",
				"en": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/gpn_preview--2020.png",
				"md": "/images/content/ru/cases/gpn_preview--2020.png",
				"sm": "/images/content/ru/cases/gpn_preview--2020.png",
				"xl": "/images/content/ru/cases/gpn_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/gazprom-neft-2020"
		},
		{
			"id": 93,
			"title": {
				"ru": "GV GOLD Высочайший",
				"en": "GV GOLD Высочайший"
			},
			"link": "cases/gvgold-2020",
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/gv-gold_preview--2020.png",
				"md": "/images/content/ru/cases/gv-gold_preview--2020.png",
				"sm": "/images/content/ru/cases/gv-gold_preview--2020.png",
				"xl": "/images/content/ru/cases/gv-gold_preview--2020.png"
			},
			"years": [
				2020
			]
		},
		{
			"id": 94,
			"title": {
				"ru": "Интеко",
				"en": "Интеко"
			},
			"link": "cases/inteko-2020",
			"subTitle": {
				"ru": "Интерактивный годовой отчет-2020",
				"en": "Интерактивный годовой отчет-2020"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/inteko_preview--2020.png",
				"md": "/images/content/ru/cases/inteko_preview--2020.png",
				"sm": "/images/content/ru/cases/inteko_preview--2020.png",
				"xl": "/images/content/ru/cases/inteko_preview--2020.png"
			},
			"years": [
				2020
			]
		},
		{
			"id": 95,
			"title": {
				"ru": "ВТБ",
				"en": "ВТБ"
			},
			"link": "cases/vtb-2020",
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020",
				"en": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/vtb_preview--2020.png",
				"md": "/images/content/ru/cases/vtb_preview--2020.png",
				"sm": "/images/content/ru/cases/vtb_preview--2020.png",
				"xl": "/images/content/ru/cases/vtb_preview--2020.png"
			},
			"years": [
				2020
			]
		},
		{
			"id": 96,
			"title": {
				"ru": "Интер РАО",
				"en": "Интер РАО"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/interrao-preview--2020.png",
				"md": "/images/content/ru/cases/interrao-preview--2020.png",
				"sm": "/images/content/ru/cases/interrao-preview--2020.png",
				"xl": "/images/content/ru/cases/interrao-preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/interrao-2020"
		},
		{
			"id": 97,
			"title": {
				"ru": "Лукойл",
				"en": "Лукойл"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом развитии-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020",
				"en": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом развитии-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/lukoil_preview--2020.png",
				"md": "/images/content/ru/cases/lukoil_preview--2020.png",
				"sm": "/images/content/ru/cases/lukoil_preview--2020.png",
				"xl": "/images/content/ru/cases/lukoil_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/lukoil-2020"
		},
		{
			"id": 98,
			"title": {
				"ru": "ЕВРАЗ",
				"en": "ЕВРАЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020",
				"en": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом&nbsp;развитии-2020<br>Интерактивный годовой отчет-2020<br>Интерактивный отчет об&nbsp;устойчивом&nbsp;развитии-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/evraz_preview--2020.png",
				"md": "/images/content/ru/cases/evraz_preview--2020.png",
				"sm": "/images/content/ru/cases/evraz_preview--2020.png",
				"xl": "/images/content/ru/cases/evraz_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/evraz-2020"
		},
		{
			"id": 99,
			"title": {
				"ru": "КазМунайГаз",
				"en": "КазМунайГаз"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/kmg_preview--2020.png",
				"md": "/images/content/ru/cases/kmg_preview--2020.png",
				"sm": "/images/content/ru/cases/kmg_preview--2020.png",
				"xl": "/images/content/ru/cases/kmg_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/kmg-2020"
		},
		{
			"id": 100,
			"title": {
				"ru": "Московская Биржа",
				"en": "Московская Биржа"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом развитии-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Отчет об&nbsp;устойчивом развитии-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/mosbirzha_preview--2020.png",
				"md": "/images/content/ru/cases/mosbirzha_preview--2020.png",
				"sm": "/images/content/ru/cases/mosbirzha_preview--2020.png",
				"xl": "/images/content/ru/cases/mosbirzha_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/moex-2020"
		},
		{
			"id": 101,
			"title": {
				"en": "МТС",
				"ru": "МТС"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mts_preview--2020.png",
				"md": "/images/content/ru/cases/mts_preview--2020.png",
				"sm": "/images/content/ru/cases/mts_preview--2020.png",
				"xl": "/images/content/ru/cases/mts_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/mts-2020"
		},
		{
			"id": 102,
			"title": {
				"ru": "НЛМК",
				"en": "НЛМК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nlmk_preview--2020.png",
				"md": "/images/content/ru/cases/nlmk_preview--2020.png",
				"sm": "/images/content/ru/cases/nlmk_preview--2020.png",
				"xl": "/images/content/ru/cases/nlmk_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/nlmk-2020"
		},
		{
			"id": 103,
			"title": {
				"ru": "НМТП",
				"en": "НМТП"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nmtp_preview--2020.png",
				"md": "/images/content/ru/cases/nmtp_preview--2020.png",
				"sm": "/images/content/ru/cases/nmtp_preview--2020.png",
				"xl": "/images/content/ru/cases/nmtp_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/nmtp-2020"
		},
		{
			"id": 104,
			"title": {
				"ru": "Норникель",
				"en": "Норникель"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/nornickel_preview--2020.png",
				"md": "/images/content/ru/cases/nornickel_preview--2020.png",
				"sm": "/images/content/ru/cases/nornickel_preview--2020.png",
				"xl": "/images/content/ru/cases/nornickel_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/nornickel-2020"
		},
		{
			"id": 105,
			"title": {
				"en": "Почта России",
				"ru": "Почта России"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/russian-post_preview--2020.png",
				"md": "/images/content/ru/cases/russian-post_preview--2020.png",
				"sm": "/images/content/ru/cases/russian-post_preview--2020.png",
				"xl": "/images/content/ru/cases/russian-post_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/russian-post-2020"
		},
		{
			"id": 106,
			"title": {
				"en": "РЖД",
				"ru": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rzd_preview--2020.png",
				"md": "/images/content/ru/cases/rzd_preview--2020.png",
				"sm": "/images/content/ru/cases/rzd_preview--2020.png",
				"xl": "/images/content/ru/cases/rzd_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/rzd-2020"
		},
		{
			"id": 107,
			"title": {
				"en": "Роснефть",
				"ru": "Роснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/rosneft_preview--2020.png",
				"md": "/images/content/ru/cases/rosneft_preview--2020.png",
				"sm": "/images/content/ru/cases/rosneft_preview--2020.png",
				"xl": "/images/content/ru/cases/rosneft_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/rosneft-2020"
		},
		{
			"id": 108,
			"title": {
				"en": "Русагро",
				"ru": "Русагро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusagro_preview--2020.png",
				"md": "/images/content/ru/cases/rusagro_preview--2020.png",
				"sm": "/images/content/ru/cases/rusagro_preview--2020.png",
				"xl": "/images/content/ru/cases/rusagro_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/rusagro-2020"
		},
		{
			"id": 109,
			"title": {
				"ru": "РусГидро",
				"en": "РусГидро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020<br>ESEF-Compliance",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/rusgidro_preview--2020.png",
				"md": "/images/content/ru/cases/rusgidro_preview--2020.png",
				"sm": "/images/content/ru/cases/rusgidro_preview--2020.png",
				"xl": "/images/content/ru/cases/rusgidro_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/rushydro-2020"
		},
		{
			"id": 110,
			"title": {
				"en": "СУЭК",
				"ru": "СУЭК"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2020<br>Интерактивный интегрированный отчет-2020",
				"en": "Интегрированный отчет-2020<br>Интерактивный интегрированный отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/suek_preview--2020.png",
				"md": "/images/content/ru/cases/suek_preview--2020.png",
				"sm": "/images/content/ru/cases/suek_preview--2020.png",
				"xl": "/images/content/ru/cases/suek_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/suek-2020"
		},
		{
			"id": 111,
			"title": {
				"en": "ФосАгро",
				"ru": "ФосАгро"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2020<br>Интерактивный интегрированный отчет-2020<br>TCFD-отчет",
				"en": "Интегрированный отчет-2020<br>Интерактивный интегрированный отчет-2020<br>TCFD-отчет"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/phosagro_preview--2020.png",
				"md": "/images/content/ru/cases/phosagro_preview--2020.png",
				"sm": "/images/content/ru/cases/phosagro_preview--2020.png",
				"xl": "/images/content/ru/cases/phosagro_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/phosagro-2020"
		},
		{
			"id": 112,
			"title": {
				"en": "Лента",
				"ru": "Лента"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>ESEF-отчет",
				"en": "Годовой отчет-2020<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/lenta_preview--2020.png",
				"md": "/images/content/ru/cases/lenta_preview--2020.png",
				"sm": "/images/content/ru/cases/lenta_preview--2020.png",
				"xl": "/images/content/ru/cases/lenta_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/lenta-2020"
		},
		{
			"id": 113,
			"title": {
				"en": "Яндекс",
				"ru": "Яндекс"
			},
			"subTitle": {
				"ru": "Отчет об&nbsp;устойчивом развитии-2020",
				"en": "Отчет об&nbsp;устойчивом развитии-2020"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/yandex_preview--2020.png",
				"md": "/images/content/ru/cases/yandex_preview--2020.png",
				"sm": "/images/content/ru/cases/yandex_preview--2020.png",
				"xl": "/images/content/ru/cases/yandex_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/yandex-2020"
		},
		{
			"id": 114,
			"title": {
				"ru": "Трансконтейнер",
				"en": "Трансконтейнер"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/transkonteiner_preview--2020.png",
				"md": "/images/content/ru/cases/transkonteiner_preview--2020.png",
				"sm": "/images/content/ru/cases/transkonteiner_preview--2020.png",
				"xl": "/images/content/ru/cases/transkonteiner_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/transkonteiner-2020"
		},
		{
			"id": 115,
			"title": {
				"ru": "ТМК",
				"en": "ТМК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/tmk_preview--2020.png",
				"md": "/images/content/ru/cases/tmk_preview--2020.png",
				"sm": "/images/content/ru/cases/tmk_preview--2020.png",
				"xl": "/images/content/ru/cases/tmk_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/tmk-2020"
		},
		{
			"id": 116,
			"title": {
				"en": "Совкомфлот",
				"ru": "Совкомфлот"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/scf_preview--2020.png",
				"md": "/images/content/ru/cases/scf_preview--2020.png",
				"sm": "/images/content/ru/cases/scf_preview--2020.png",
				"xl": "/images/content/ru/cases/scf_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/scf-group-2020"
		},
		{
			"id": 117,
			"title": {
				"en": "ОКЕЙ",
				"ru": "ОКЕЙ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/ok_preview--2020.png",
				"md": "/images/content/ru/cases/ok_preview--2020.png",
				"sm": "/images/content/ru/cases/ok_preview--2020.png",
				"xl": "/images/content/ru/cases/ok_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/okey-2020"
		},
		{
			"id": 118,
			"title": {
				"en": "Россети Центр",
				"ru": "Россети Центр"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020",
				"en": "Годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk-center_preview--2020.png",
				"md": "/images/content/ru/cases/mrsk-center_preview--2020.png",
				"sm": "/images/content/ru/cases/mrsk-center_preview--2020.png",
				"xl": "/images/content/ru/cases/mrsk-center_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/mrsk-center-2020"
		},
		{
			"id": 119,
			"title": {
				"en": "Россети Центр и Приволжье",
				"ru": "Россети Центр и Приволжье"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2020",
				"en": "Интегрированный годовой отчет-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/mrsk-cp_preview--2020.png",
				"md": "/images/content/ru/cases/mrsk-cp_preview--2020.png",
				"sm": "/images/content/ru/cases/mrsk-cp_preview--2020.png",
				"xl": "/images/content/ru/cases/mrsk-cp_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/mrsk-cp-2020"
		},
		{
			"id": 120,
			"title": {
				"en": "Fesco",
				"ru": "Fesco"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/fesco_preview--2020.png",
				"md": "/images/content/ru/cases/fesco_preview--2020.png",
				"sm": "/images/content/ru/cases/fesco_preview--2020.png",
				"xl": "/images/content/ru/cases/fesco_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/fesco-2020"
		},
		{
			"id": 121,
			"title": {
				"en": "Новикомбанк",
				"ru": "Новикомбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020",
				"en": "Годовой отчет-2020<br>Интерактивный годовой отчет-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/novikombank_preview--2020.png",
				"md": "/images/content/ru/cases/novikombank_preview--2020.png",
				"sm": "/images/content/ru/cases/novikombank_preview--2020.png",
				"xl": "/images/content/ru/cases/novikombank_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/novikombank-2020"
		},
		{
			"id": 122,
			"title": {
				"en": "En+ GROUP",
				"ru": "En+ GROUP"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/EN+_preview--2021.png",
				"md": "/images/content/ru/cases/2021/EN+_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/EN+_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/EN+_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/enplus-2021"
		},
		{
			"id": 123,
			"title": {
				"en": "FESCO",
				"ru": "FESCO"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Fesco_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Fesco_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Fesco_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Fesco_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/fesco-2021"
		},
		{
			"id": 124,
			"title": {
				"en": "Global Ports",
				"ru": "Global Ports"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный годовой отчет-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный годовой отчет-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/global_ports_preview--2021.png",
				"md": "/images/content/ru/cases/2021/global_ports_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/global_ports_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/global_ports_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/globalports-2021"
		},
		{
			"id": 125,
			"title": {
				"en": "GV GOLD",
				"ru": "GV GOLD"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/GV_gold_preview--2021.png",
				"md": "/images/content/ru/cases/2021/GV_gold_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/GV_gold_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/GV_gold_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/gvgold-2021"
		},
		{
			"id": 126,
			"title": {
				"en": "Positive Technologies",
				"ru": "Positive Technologies"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/pt_preview--2021.png",
				"md": "/images/content/ru/cases/2021/pt_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/pt_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/pt_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/pt-2021"
		},
		{
			"id": 127,
			"title": {
				"en": "X5 Group",
				"ru": "X5 Group"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2021",
				"en": "Отчет об устойчивом развитии-2021"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/x5_preview--2021.png",
				"md": "/images/content/ru/cases/2021/x5_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/x5_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/x5_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/x5-2021"
		},
		{
			"id": 128,
			"title": {
				"en": "Аэрофлот",
				"ru": "Аэрофлот"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Презентация<br>Фотосъемка",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Презентация<br>Фотосъемка"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Aeroflot_preview--2021.jpg",
				"md": "/images/content/ru/cases/2021/Aeroflot_preview--2021.jpg",
				"sm": "/images/content/ru/cases/2021/Aeroflot_preview--2021.jpg",
				"xl": "/images/content/ru/cases/2021/Aeroflot_preview--2021.jpg"
			},
			"years": [
				2021
			],
			"link": "cases/aeroflot-2021"
		},
		{
			"id": 129,
			"title": {
				"en": "Газпром",
				"ru": "Газпром"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2021<br>Фотосъемка",
				"en": "Отчет об устойчивом развитии-2021<br>Фотосъемка"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Gazprom_web.png",
				"md": "/images/content/ru/cases/2021/Gazprom_web.png",
				"sm": "/images/content/ru/cases/2021/Gazprom_web.png",
				"xl": "/images/content/ru/cases/2021/Gazprom_web.png"
			},
			"years": [
				2021
			],
			"link": "cases/gazprom-2021"
		},
		{
			"id": 130,
			"title": {
				"en": "ДелоПортс",
				"ru": "ДелоПортс"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Deloports_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Deloports_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Deloports_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Deloports_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/deloports-2021"
		},
		{
			"id": 131,
			"title": {
				"en": "Детский мир",
				"ru": "Детский мир"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/detmir_preview--2021.png",
				"md": "/images/content/ru/cases/2021/detmir_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/detmir_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/detmir_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/detskiy-mir-2021"
		},
		{
			"id": 132,
			"title": {
				"en": "ЕВРАЗ",
				"ru": "ЕВРАЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Evraz_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Evraz_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Evraz_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Evraz_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/evraz-2021"
		},
		{
			"id": 132,
			"title": {
				"en": "ИНТЕКО",
				"ru": "ИНТЕКО"
			},
			"subTitle": {
				"ru": "Интерактивный годовой отчет-2021",
				"en": "Интерактивный годовой отчет-2021"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/inteco_preview--2021.png",
				"md": "/images/content/ru/cases/2021/inteco_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/inteco_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/inteco_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/inteko-2021"
		},
		{
			"id": 133,
			"title": {
				"en": "Интер РАО",
				"ru": "Интер РАО"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2021<br>Интерактивный интегрированный годовой отчет-2021",
				"en": "Интегрированный годовой отчет-2021<br>Интерактивный интегрированный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/interRAO_preview--2021.png",
				"md": "/images/content/ru/cases/2021/interRAO_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/interRAO_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/interRAO_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/interrao-2021"
		},
		{
			"id": 133,
			"title": {
				"en": "Лента",
				"ru": "Лента"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Lenta_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Lenta_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Lenta_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Lenta_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/lenta-2021"
		},
		{
			"id": 134,
			"title": {
				"en": "ЛСР",
				"ru": "ЛСР"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/lsr_preview--2021.png",
				"md": "/images/content/ru/cases/2021/lsr_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/lsr_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/lsr_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/lsr-2021"
		},
		{
			"id": 134,
			"title": {
				"en": "ЛУКОЙЛ",
				"ru": "ЛУКОЙЛ"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/lukoil_preview--2021.png",
				"md": "/images/content/ru/cases/2021/lukoil_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/lukoil_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/lukoil_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/lukoil-2021"
		},
		{
			"id": 140,
			"title": {
				"en": "Мать и дитя",
				"ru": "Мать и дитя"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/md_preview--2021.png",
				"md": "/images/content/ru/cases/2021/md_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/md_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/md_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/mother-and-child-2021"
		},
		{
			"id": 141,
			"title": {
				"en": "НЛМК",
				"ru": "НЛМК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/nlmk_preview--2021.png",
				"md": "/images/content/ru/cases/2021/nlmk_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/nlmk_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/nlmk_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/nlmk-2021"
		},
		{
			"id": 142,
			"title": {
				"en": "Новикомбанк",
				"ru": "Новикомбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Novikom_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Novikom_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Novikom_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Novikom_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/novikombank-2021"
		},
		{
			"id": 143,
			"title": {
				"en": "Норникель",
				"ru": "Норникель"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Nornikel_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Nornikel_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Nornikel_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Nornikel_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/nornickel-2021"
		},
		{
			"id": 144,
			"title": {
				"en": "О'КЕЙ",
				"ru": "О'КЕЙ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/okey_preview--2021.png",
				"md": "/images/content/ru/cases/2021/okey_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/okey_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/okey_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/okey-2021"
		},
		{
			"id": 145,
			"title": {
				"en": "ОТЛК ЕРА",
				"ru": "ОТЛК ЕРА"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/otlk_preview--2021.png",
				"md": "/images/content/ru/cases/2021/otlk_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/otlk_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/otlk_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/otlk-era-2021"
		},
		{
			"id": 146,
			"title": {
				"en": "ОЭЗ «Технополис Москва»",
				"ru": "ОЭЗ «Технополис Москва»"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/tehnopolis_preview--2021.png",
				"md": "/images/content/ru/cases/2021/tehnopolis_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/tehnopolis_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/tehnopolis_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/technomoscow-2021"
		},
		{
			"id": 147,
			"title": {
				"en": "Почта России",
				"ru": "Почта России"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/pochta_preview--2021.png",
				"md": "/images/content/ru/cases/2021/pochta_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/pochta_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/pochta_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/russian-post-2021"
		},
		{
			"id": 148,
			"title": {
				"en": "Распадская",
				"ru": "Распадская"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/raspadskaya_preview--2021.png",
				"md": "/images/content/ru/cases/2021/raspadskaya_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/raspadskaya_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/raspadskaya_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/raspadskaya-2021"
		},
		{
			"id": 149,
			"title": {
				"en": "РЖД",
				"ru": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/rzd_preview--2021.png",
				"md": "/images/content/ru/cases/2021/rzd_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/rzd_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/rzd_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rzd-2021"
		},
		{
			"id": 150,
			"title": {
				"en": "РЖД Управление активами",
				"ru": "РЖД Управление активами"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/rzd-assets_preview--2021.png",
				"md": "/images/content/ru/cases/2021/rzd-assets_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/rzd-assets_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/rzd-assets_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rzd-assets-2021"
		},
		{
			"id": 151,
			"title": {
				"en": "Рольф",
				"ru": "Рольф"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Rolf_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Rolf_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Rolf_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Rolf_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rolf-2021"
		},
		{
			"id": 152,
			"title": {
				"en": "Роснефть",
				"ru": "Роснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Rosneft_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Rosneft_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Rosneft_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Rosneft_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rosneft-2021"
		},
		{
			"id": 153,
			"title": {
				"en": "Россети Кубань",
				"ru": "Россети Кубань"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Rosseti_Cuban_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Rosseti_Cuban_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Rosseti_Cuban_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Rosseti_Cuban_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rosseti-kuban-2021"
		},
		{
			"id": 154,
			"title": {
				"en": "Россети Центр",
				"ru": "Россети Центр"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2021",
				"en": "Интегрированный годовой отчет-2021"
			},
			"groups": [
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/Rosseti_Centr_preview--2021.png",
				"md": "/images/content/ru/cases/2021/Rosseti_Centr_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/Rosseti_Centr_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/Rosseti_Centr_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rosseti-centr-2021"
		},
		{
			"id": 155,
			"title": {
				"en": "Россети Центр и Приволжье",
				"ru": "Россети Центр и Приволжье"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2021",
				"en": "Интегрированный годовой отчет-2021"
			},
			"groups": [
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/rosseti_cp_preview--2021.png",
				"md": "/images/content/ru/cases/2021/rosseti_cp_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/rosseti_cp_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/rosseti_cp_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rosseti-cp-2021"
		},
		{
			"id": 156,
			"title": {
				"en": "Русагро",
				"ru": "Русагро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный годовой отчет-2021<br>Интерактивный отчет об устойчивом развитии-2021<br>ESEF-Compliance",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021<br>Интерактивный годовой отчет-2021<br>Интерактивный отчет об устойчивом развитии-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/rusagro_preview--2021.png",
				"md": "/images/content/ru/cases/2021/rusagro_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/rusagro_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/rusagro_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/rusagro-2021"
		},
		{
			"id": 157,
			"title": {
				"en": "Самолет",
				"ru": "Самолет"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021",
				"en": "Годовой отчет-2021<br>Отчет об устойчивом развитии-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/samolet_preview--2021.png",
				"md": "/images/content/ru/cases/2021/samolet_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/samolet_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/samolet_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/samolet-2021"
		},
		{
			"id": 158,
			"title": {
				"en": "Совкомбанк",
				"ru": "Совкомбанк"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2021",
				"en": "Интегрированный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/sovkombank_preview--2021.png",
				"md": "/images/content/ru/cases/2021/sovkombank_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/sovkombank_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/sovkombank_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/sovkombank-2021"
		},
		{
			"id": 159,
			"title": {
				"en": "Траст",
				"ru": "Траст"
			},
			"subTitle": {
				"ru": "Интерактивный годовой отчет-2021",
				"en": "Интерактивный годовой отчет-2021"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/trust_preview--2021.png",
				"md": "/images/content/ru/cases/2021/trust_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/trust_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/trust_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/trust-2021"
		},
		{
			"id": 160,
			"title": {
				"en": "ФПК",
				"ru": "ФПК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021",
				"en": "Годовой отчет-2021<br>Интерактивный годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/fpk_preview--2021.png",
				"md": "/images/content/ru/cases/2021/fpk_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/fpk_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/fpk_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/fpk-2021"
		},
		{
			"id": 161,
			"title": {
				"en": "ЯТЭК",
				"ru": "ЯТЭК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2021",
				"en": "Годовой отчет-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2021/yatek_preview--2021.png",
				"md": "/images/content/ru/cases/2021/yatek_preview--2021.png",
				"sm": "/images/content/ru/cases/2021/yatek_preview--2021.png",
				"xl": "/images/content/ru/cases/2021/yatek_preview--2021.png"
			},
			"years": [
				2021
			],
			"link": "cases/yatek-2021"
		},
		{
			"id": 162,
			"title": {
				"en": "Аэрофлот",
				"ru": "Аэрофлот"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/aeroflot_preview--2022.png",
				"md": "/images/content/ru/cases/2022/aeroflot_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/aeroflot_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/aeroflot_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/aeroflot-2022"
		},
		{
			"id": 163,
			"title": {
				"en": "АФК Система",
				"ru": "АФК Система"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>ESEF-отчет",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/afk_preview--2022.png",
				"md": "/images/content/ru/cases/2022/afk_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/afk_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/afk_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/afk-2022"
		},
		{
			"id": 164,
			"title": {
				"en": "Газпром",
				"ru": "Газпром"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2022",
				"en": "Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/gazprom_preview--2022.png",
				"md": "/images/content/ru/cases/2022/gazprom_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/gazprom_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/gazprom_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/gazprom-2022"
		},
		{
			"id": 165,
			"title": {
				"en": "ЕвроХим",
				"ru": "ЕвроХим"
			},
			"subTitle": {
				"ru": "Годовой обзор-2022",
				"en": "Годовой обзор-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/evrohim_preview--2022.png",
				"md": "/images/content/ru/cases/2022/evrohim_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/evrohim_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/evrohim_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/evrohim-2022"
		},
		{
			"id": 166,
			"title": {
				"en": "ИНАРКТИКА",
				"ru": "ИНАРКТИКА"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/inarctica_preview--2022.png",
				"md": "/images/content/ru/cases/2022/inarctica_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/inarctica_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/inarctica_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/inarctica-2022"
		},
		{
			"id": 167,
			"title": {
				"en": "Лента",
				"ru": "Лента"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2022",
				"en": "Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/lenta_preview--2022.png",
				"md": "/images/content/ru/cases/2022/lenta_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/lenta_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/lenta_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/lenta-2022"
		},
		{
			"id": 168,
			"title": {
				"en": "ЛСР",
				"ru": "ЛСР"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/lsr_preview--2022.png",
				"md": "/images/content/ru/cases/2022/lsr_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/lsr_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/lsr_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/lsr-2022"
		},
		{
			"id": 169,
			"title": {
				"en": "Лукойл",
				"ru": "Лукойл"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2022",
				"en": "Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/lukoil_preview--2022.png",
				"md": "/images/content/ru/cases/2022/lukoil_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/lukoil_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/lukoil_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/lukoil-2022"
		},
		{
			"id": 170,
			"title": {
				"en": "М.Видео-Эльдорадо",
				"ru": "М.Видео-Эльдорадо"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/mvideo_preview--2022.png",
				"md": "/images/content/ru/cases/2022/mvideo_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/mvideo_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/mvideo_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/mvideo-2022"
		},
		{
			"id": 171,
			"title": {
				"en": "МосБиржа",
				"ru": "МосБиржа"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный отчет об устойчивом развитии-2022<br>ESG Guide",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный отчет об устойчивом развитии-2022<br>ESG Guide"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/moex_preview--2022.png",
				"md": "/images/content/ru/cases/2022/moex_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/moex_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/moex_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/moex-2022"
		},
		{
			"id": 172,
			"title": {
				"en": "Норильский Никель",
				"ru": "Норильский Никель"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022<br>ESEF-отчет",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/nornickel_preview--2022.png",
				"md": "/images/content/ru/cases/2022/nornickel_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/nornickel_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/nornickel_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/nornickel-2022"
		},
		{
			"id": 173,
			"title": {
				"en": "Окей",
				"ru": "Окей"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>ESEF-отчет",
				"en": "Годовой отчет-2022<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/okey_preview--2022.png",
				"md": "/images/content/ru/cases/2022/okey_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/okey_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/okey_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/okey-2022"
		},
		{
			"id": 174,
			"title": {
				"en": "РЖД",
				"ru": "РЖД"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный отчет об устойчивом развитии-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>Интерактивный отчет об устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr",
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rzd_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rzd_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rzd_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rzd_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rzd-2022"
		},
		{
			"id": 175,
			"title": {
				"en": "Рольф",
				"ru": "Рольф"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rolf_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rolf_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rolf_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rolf_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rolf-2022"
		},
		{
			"id": 176,
			"title": {
				"en": "Россети Кубань",
				"ru": "Россети Кубань"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rosseti-kuban_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rosseti-kuban_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rosseti-kuban_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rosseti-kuban_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rosseti-kuban-2022"
		},
		{
			"id": 177,
			"title": {
				"en": "Россети СЗ",
				"ru": "Россети СЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rosseti-sz_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rosseti-sz_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rosseti-sz_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rosseti-sz_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rosseti-sz-2022"
		},
		{
			"id": 178,
			"title": {
				"en": "Россети Центр",
				"ru": "Россети Центр"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rosseti-center_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rosseti-center_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rosseti-center_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rosseti-center_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rosseti-center-2022"
		},
		{
			"id": 179,
			"title": {
				"en": "Россети Центр и Приволжье",
				"ru": "Россети Центр и Приволжье"
			},
			"subTitle": {
				"ru": "Интегрированный годовой отчет-2022<br>Интерактивный интегрированный отчет-2022",
				"en": "Интегрированный годовой отчет-2022<br>Интерактивный интегрированный отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rosseti-cip_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rosseti-cip_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rosseti-cip_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rosseti-cip_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rosseti-cip-2022"
		},
		{
			"id": 180,
			"title": {
				"en": "РусГидро",
				"ru": "РусГидро"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rushydro_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rushydro_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rushydro_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rushydro_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rushydro-2022"
		},
		{
			"id": 181,
			"title": {
				"en": "Совкомфлот",
				"ru": "Совкомфлот"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/sovkomflot_preview--2022.png",
				"md": "/images/content/ru/cases/2022/sovkomflot_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/sovkomflot_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/sovkomflot_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/sovkomflot-2022"
		},
		{
			"id": 182,
			"title": {
				"en": "Т+",
				"ru": "Т+"
			},
			"subTitle": {
				"ru": "Отчет от устойчивом развитии-2022",
				"en": "Отчет от устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/tplus_preview--2022.png",
				"md": "/images/content/ru/cases/2022/tplus_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/tplus_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/tplus_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/tplus-2022"
		},
		{
			"id": 183,
			"title": {
				"en": "СУЭК",
				"ru": "СУЭК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/suek_preview--2022.png",
				"md": "/images/content/ru/cases/2022/suek_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/suek_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/suek_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/suek-2022"
		},
		{
			"id": 184,
			"title": {
				"en": "ФосАгро",
				"ru": "ФосАгро"
			},
			"subTitle": {
				"ru": "Интегрированный отчет-2022<br>Интерактивный интегрированный отчет-2022",
				"en": "Интегрированный отчет-2022<br>Интерактивный интегрированный отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/phosagro_preview--2022.png",
				"md": "/images/content/ru/cases/2022/phosagro_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/phosagro_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/phosagro_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/phosagro-2022"
		},
		{
			"id": 185,
			"title": {
				"en": "ФПК",
				"ru": "ФПК"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/fpc_preview--2022.png",
				"md": "/images/content/ru/cases/2022/fpc_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/fpc_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/fpc_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/fpc-2022"
		},
		{
			"id": 186,
			"title": {
				"en": "Элемент",
				"ru": "Элемент"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022",
				"en": "Годовой отчет-2022"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/element_preview--2022.png",
				"md": "/images/content/ru/cases/2022/element_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/element_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/element_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/element-2022"
		},
		{
			"id": 187,
			"title": {
				"en": "Эн+",
				"ru": "Эн+"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>ESEF-отчет",
				"en": "Годовой отчет-2022<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/enplus_preview--2022.png",
				"md": "/images/content/ru/cases/2022/enplus_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/enplus_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/enplus_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/enplus-2022"
		},
		{
			"id": 188,
			"title": {
				"en": "FESCO",
				"ru": "FESCO"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/fesco_preview--2022.png",
				"md": "/images/content/ru/cases/2022/fesco_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/fesco_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/fesco_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/fesco-2022"
		},
		{
			"id": 189,
			"title": {
				"en": "FixPrice",
				"ru": "FixPrice"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>ESEF-отчет",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022<br>ESEF-отчет"
			},
			"groups": [
				"annual-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/fixprice_preview--2022.png",
				"md": "/images/content/ru/cases/2022/fixprice_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/fixprice_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/fixprice_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/fixprice-2022"
		},
		{
			"id": 190,
			"title": {
				"en": "Global Ports",
				"ru": "Global Ports"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/globalports_preview--2022.png",
				"md": "/images/content/ru/cases/2022/globalports_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/globalports_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/globalports_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/globalports-2022"
		},
		{
			"id": 191,
			"title": {
				"en": "Positive technologies",
				"ru": "Positive technologies"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/pt_preview--2022.png",
				"md": "/images/content/ru/cases/2022/pt_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/pt_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/pt_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/pt-2022"
		},
		{
			"id": 192,
			"title": {
				"en": "VK",
				"ru": "VK"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/vk_preview--2022.png",
				"md": "/images/content/ru/cases/2022/vk_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/vk_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/vk_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/vk-2022"
		},
		{
			"id": 193,
			"title": {
				"en": "Whoosh",
				"ru": "Whoosh"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022",
				"en": "Годовой отчет-2022<br>Интерактивный годовой отчет-2022"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/whoosh_preview--2022.png",
				"md": "/images/content/ru/cases/2022/whoosh_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/whoosh_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/whoosh_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/whoosh-2022"
		},
		{
			"id": 194,
			"title": {
				"en": "Камаз",
				"ru": "Камаз"
			},
			"subTitle": {
				"ru": "Отчет об устойчивом развитии-2022",
				"en": "Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/kamaz_preview--2022.png",
				"md": "/images/content/ru/cases/2022/kamaz_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/kamaz_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/kamaz_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/kamaz-2022"
		},
		{
			"id": 195,
			"title": {
				"en": "Роснефть",
				"ru": "Роснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022",
				"en": "Годовой отчет-2022<br>Отчет об устойчивом развитии-2022"
			},
			"groups": [
				"annual-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/ru/cases/2022/rosneft_preview--2022.png",
				"md": "/images/content/ru/cases/2022/rosneft_preview--2022.png",
				"sm": "/images/content/ru/cases/2022/rosneft_preview--2022.png",
				"xl": "/images/content/ru/cases/2022/rosneft_preview--2022.png"
			},
			"years": [
				2022
			],
			"link": "cases/rosneft-2022"
		}
	]
};